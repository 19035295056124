<template>
    <BaseLayout :user="user">
        <div class="edit-proposed-transaction-page">
            <header class="page-header">
                <h1>{{ isEditing ? 'Edit Proposed Transaction' : 'Add Proposed Transaction' }}</h1>
            </header>

            <form class="proposed-transaction-form" @submit.prevent="submitProposedTransaction">
                <!-- Transaction Type -->
                <FieldErrorWrapper :error="errors.type">
                    <OptionSelector
                        v-model="form.type"
                        title="Transaction Type"
                        :options="transactionTypeOptions"
                    />
                </FieldErrorWrapper>

                <!-- Transaction Name -->
                <FieldErrorWrapper :error="errors.name">
                    <TextField v-model="form.name" title="Transaction Name" :required="false"></TextField>
                </FieldErrorWrapper>

                <!-- Amount and Currency -->
                <FieldErrorWrapper :error="errors.balance">
                    <div class="row">
                        <CurrencyTextField v-model="form.balance.amount" title="Amount"></CurrencyTextField>
                        <OptionSelector
                            v-model="form.balance.currency"
                            title="Currency"
                            :options="currencyOptions"
                        />
                    </div>
                </FieldErrorWrapper>

                <!-- Proposed Date -->
                <FieldErrorWrapper :error="errors.date">
                    <DatePicker
                        v-model="form.date"
                        title="Proposed Date"
                    />
                </FieldErrorWrapper>

                <!-- Notes -->
                <FieldErrorWrapper :error="errors.notes">
                    <TextArea v-model="form.notes" title="Notes"></TextArea>
                </FieldErrorWrapper>

                <!-- Origin Wallet and Divisions Row (only for transfers) -->
                <div v-if="form.type === 'transfer'">
                    <FieldErrorWrapper :error="errors.origin">
                        <div class="row">
                            <OptionSelector
                                v-if="wallets.length > 0"
                                v-model="form.originWallet"
                                title="Origin Wallet"
                                :options="walletOptions"
                                @optionSelected="loadOriginDivisions"
                            />
                            <OptionSelector
                                v-if="originDivisions.length > 0"
                                v-model="form.origin"
                                title="Origin Division"
                                :options="originDivisionsOptions"
                            />
                        </div>
                    </FieldErrorWrapper>
                </div>

                <!-- Destination Wallet and Divisions Row -->
                <FieldErrorWrapper :error="errors.destination">
                    <div class="row">
                        <OptionSelector
                            v-if="wallets.length > 0"
                            v-model="form.destinationWallet"
                            title="Destination Wallet"
                            :options="walletOptions"
                            @optionSelected="loadDestinationDivisions"
                        />
                        <OptionSelector
                            v-if="destinationDivisions.length > 0"
                            v-model="form.destination"
                            title="Destination Division"
                            :options="destinationDivisionsOptions"
                        />
                    </div>
                </FieldErrorWrapper>

                <!-- Submit Button -->
                <div class="form-actions">
                    <button class="submit-button" type="submit" :disabled="isLoading">
                        <i class="bi bi-save"></i>
                        {{ isLoading ? "Submitting..." : isEditing ? "Save Changes" : "Add Proposed Transaction" }}
                    </button>
                </div>
            </form>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import TextField from '@/components/TextField/TextField.vue';
import CurrencyTextField from '@/components/TextField/CurrencyTextField.vue';
import OptionSelector from '@/components/OptionSelector.vue';
import FieldErrorWrapper from '@/components/FieldErrorWrapper.vue';
import DatePicker from '@/components/DatePicker.vue';
import TextArea from '@/components/TextArea.vue';
import api from '@/services/api';

export default {
    name: 'EditProposedTransactionPage',
    components: {
        BaseLayout,
        TextField,
        CurrencyTextField,
        OptionSelector,
        FieldErrorWrapper,
        DatePicker,
        TextArea,
    },
    data() {
        return {
            form: {
                type: 'expense', // Default type
                name: '',
                balance: {
                    amount: 0,
                    currency: null,
                },
                date: null,
                notes: '',
                originWallet: null,
                destinationWallet: null,
                origin: null,
                destination: null,
            },
            transactionId: null,
            isLoading: false,
            errors: {},

            wallets: [],
            originDivisions: [],
            destinationDivisions: [],

            transactionTypeOptions: [
                { id: 'expense', display: 'Expense' },
                { id: 'income', display: 'Income' },
                { id: 'transfer', display: 'Transfer' },
            ],
            currencyOptions: [
                { id: 'MXN', display: 'MXN' },
                { id: 'USD', display: 'USD' },
                { id: 'EUR', display: 'EUR' },
                { id: 'GBP', display: 'GBP' },
            ],
        };
    },
    computed: {
        isEditing() {
            return this.transactionId !== null;
        },
        walletOptions() {
            return this.wallets.map((wallet) => ({
                id: wallet.id,
                display: wallet.name,
            }));
        },
        originDivisionsOptions() {
            return this.originDivisions.map((division) => ({
                id: division.id,
                display: division.name,
            }));
        },
        destinationDivisionsOptions() {
            return this.destinationDivisions.map((division) => ({
                id: division.id,
                display: division.name,
            }));
        },
    },
    async mounted() {
        this.transactionId = this.$route.params.id || null;
        await this.loadWallets();
        if (this.isEditing) {
            await this.loadProposedTransactionData();
        }
    },
    methods: {
        async loadProposedTransactionData() {
            try {
                this.isLoading = true;
                const response = await api.get(`wallet/proposed-transactions/${this.transactionId}/`);
                const transaction = response.data;

                this.form = {
                    type: transaction.origin ? 'transfer' : transaction.balance.amount < 0 ? 'expense' : 'income',
                    name: transaction.name || '',
                    balance: {
                        amount: Math.abs(transaction.balance.amount) || 0,
                        currency: transaction.balance.currency || null,
                    },
                    date: transaction.date
                        ? new Date(transaction.date).toISOString().split('T')[0]
                        : null,
                    notes: transaction.notes || '',
                    origin: transaction.origin || null,
                    destination: transaction.destination || null,
                };

                if (this.form.origin) {
                    this.form.originWallet = await this.resolveWalletFromDivision(this.form.origin);
                    await this.loadOriginDivisions(this.form.originWallet);
                }

                if (this.form.destination) {
                    this.form.destinationWallet = await this.resolveWalletFromDivision(this.form.destination);
                    await this.loadDestinationDivisions(this.form.destinationWallet);
                }
            } catch (error) {
                console.error('Error loading proposed transaction data:', error);
                alert('Failed to load proposed transaction data.');
            } finally {
                this.isLoading = false;
            }
        },
        async resolveWalletFromDivision(divisionId) {
            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                return response.data.wallet;
            } catch (error) {
                console.error('Error resolving wallet from division:', error);
                return null;
            }
        },
        async loadWallets() {
            try {
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results || [];
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets.');
            }
        },
        async loadOriginDivisions(walletId) {
            try {
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.originDivisions = response.data.results || [];
            } catch (error) {
                console.error('Error loading origin divisions:', error);
            }
        },
        async loadDestinationDivisions(walletId) {
            try {
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.destinationDivisions = response.data.results || [];
            } catch (error) {
                console.error('Error loading destination divisions:', error);
            }
        },
        async submitProposedTransaction() {
            try {
                this.isLoading = true;

                const formattedDate = this.form.date
                    ? new Date(this.form.date).toISOString().split('Z')[0]
                    : null;

                const data = {
                    ...this.form,
                    date: formattedDate,
                };

                data.balance.amount = data.type === 'expense' ? -data.balance.amount : data.balance.amount;

                if (data.type !== 'transfer') {
                    data.origin = null;
                }

                if (this.isEditing) {
                    await api.put(`wallet/proposed-transactions/${this.transactionId}/`, data);
                } else {
                    await api.post('wallet/proposed-transactions/', data);
                }

                this.$router.push({ name: 'ProposedTransactions' });
            } catch (error) {
                console.error('Error submitting proposed transaction:', error);
                alert('An error occurred. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.edit-proposed-transaction-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 1.8rem;
    color: #2b6cb0;
}

.proposed-transaction-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.row > * {
    flex: 1;
}

.form-actions {
    text-align: center;
}

.submit-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}
</style>