<template>
    <BaseLayout :user="user">
        <div class="add-transaction-page">
            <header class="page-header">
                <h1>{{ isEditing ? 'Edit Transaction' : 'Add Transaction' }}</h1>
            </header>

            <form class="transaction-form" @submit.prevent="submitTransaction">
                <!-- Toggle for Recurrent Transaction -->
                <div class="recurrent-toggle" v-if="!isEditing">
                    <label class="switch">
                        <input type="checkbox" v-model="isRecurrent" />
                        <span class="slider"></span>
                    </label>
                    <span class="recurrent-label">Recurrent</span>
                </div>

                <FieldErrorWrapper :error="errors.type">
                    <OptionSelector
                        v-model="form.type"
                        title="Transaction Type"
                        :options="transactionTypeOptions"
                    />
                </FieldErrorWrapper>

                <FieldErrorWrapper :error="errors.name">
                    <TextField v-model="form.name" title="Transaction Name" :required="false"></TextField>
                </FieldErrorWrapper>

                <!-- Amount and Currency in a Row -->
                <FieldErrorWrapper :error="errors.balance">
                    <div class="row">
                        <CurrencyTextField v-model="form.balance.amount" title="Amount"></CurrencyTextField>
                        <OptionSelector
                            v-model="form.balance.currency"
                            title="Currency"
                            :options="currencyOptions"
                        />
                    </div>
                </FieldErrorWrapper>

                <!-- Date Picker -->
                <FieldErrorWrapper :error="errors.date">
                    <DatePicker
                        v-model="form.date"
                        title="Date"
                    />
                </FieldErrorWrapper>

                <FieldErrorWrapper :error="errors.notes">
                    <TextArea v-model="form.notes" title="Notes"></TextArea>
                </FieldErrorWrapper>

                <!-- Origin Wallet and Divisions Row (only for transfers) -->
                <div v-if="form.type === 'transfer'">
                    <FieldErrorWrapper :error="errors.origin">
                        <div class="row">
                            <OptionSelector
                                v-if="wallets.length > 0"
                                v-model="form.originWallet"
                                title="Origin Wallet"
                                :options="walletOptions"
                                @optionSelected="loadOriginDivisions"
                            />
                            <OptionSelector
                                v-if="originDivisions.length > 0"
                                v-model="form.origin"
                                title="Origin Division"
                                :options="originDivisionsOptions"
                            />
                        </div>
                    </FieldErrorWrapper>
                </div>

                <!-- Destination Wallet and Divisions Row -->
                <FieldErrorWrapper :error="errors.destination">
                    <div class="row">
                        <OptionSelector
                            v-if="wallets.length > 0"
                            v-model="form.destinationWallet"
                            title="Destination Wallet"
                            :options="walletOptions"
                            @optionSelected="loadDestinationDivisions"
                        />
                        <OptionSelector
                            v-if="destinationDivisions.length > 0"
                            v-model="form.destination"
                            title="Destination Division"
                            :options="destinationDivisionsOptions"
                        />
                    </div>
                </FieldErrorWrapper>

                <!-- Recurrent Transaction Fields -->
                <div v-if="isRecurrent" class="recurrent-fields">
                    <FieldErrorWrapper :error="errors.repeat_until">
                        <DatePicker v-model="form.repeat_until" title="Repeat Until"></DatePicker>
                    </FieldErrorWrapper>

                    <div class="row" style="margin-top: 25px;">
                        <FieldErrorWrapper :error="errors.frequency_number">
                            <TextField
                                v-model="form.frequency_number"
                                type="number"
                                title="Frequency"
                            />
                        </FieldErrorWrapper>
                        <FieldErrorWrapper :error="errors.frequency">
                            <OptionSelector
                                v-model="form.frequency"
                                :options="frequencyOptions"
                            />
                        </FieldErrorWrapper>
                    </div>

                    <FieldErrorWrapper :error="errors.days_of_week">
                        <div class="days-of-week">
                            <button
                                v-for="(day, index) in daysOfWeek"
                                :key="index"
                                :class="{ active: form.days_of_week[index] }"
                                @click.prevent="toggleDay(index)"
                                type="button"
                            >
                                {{ day }}
                            </button>
                        </div>
                    </FieldErrorWrapper>
                </div>

                <!-- Submit Button -->
                <div class="form-actions">
                    <button class="submit-button" type="submit" :disabled="isLoading">
                        <i class="bi bi-plus-circle"></i>
                        {{ isLoading ? "Submitting..." : isEditing ? "Save Transaction" : "Add Transaction" }}
                    </button>
                </div>
            </form>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';
import TextField from '@/components/TextField/TextField.vue';
import TextArea from '@/components/TextArea.vue';
import OptionSelector from '@/components/OptionSelector.vue';
import CurrencyTextField from '@/components/TextField/CurrencyTextField.vue';
import FieldErrorWrapper from '@/components/FieldErrorWrapper.vue';
import DatePicker from '@/components/DatePicker.vue';

export default {
    name: 'AddTransactionPage',
    components: {
        BaseLayout,
        TextField,
        TextArea,
        OptionSelector,
        CurrencyTextField,
        FieldErrorWrapper,
        DatePicker,
    },
    data() {
        return {
            form: {
                type: 'expense', // Default type
                name: '',
                balance: {
                    amount: 0,
                    currency: null,
                },
                date: null, // Default date
                notes: '',
                originWallet: null,
                destinationWallet: null,
                origin: null,
                destination: null,
                repeat_until: null,
                frequency: null,
                frequency_number: null,
                days_of_week: [false, false, false, false, false, false, false],
            },
            isLoading: false,
            isRecurrent: false,
            errors: {},
            transactionTypeOptions: [
                { id: 'expense', display: 'Expense' },
                { id: 'income', display: 'Income' },
                { id: 'transfer', display: 'Transfer' },
            ],
            currencyOptions: [
                { id: 'MXN', display: 'MXN' },
                { id: 'USD', display: 'USD' },
                { id: 'EUR', display: 'EUR' },
                { id: 'GBP', display: 'GBP' },
            ],
            frequencyOptions: [
                { id: 'day', display: 'Day' },
                { id: 'week', display: 'Week' },
                { id: 'month', display: 'Month' },
                { id: 'year', display: 'Year' },
            ],
            daysOfWeek: ['M', 'T', 'W', 'T', 'F', 'S', 'S'], // Days of the week

            wallets: [],
            originDivisions: [],
            destinationDivisions: [],
            transactionId: null,
        };
    },
    computed: {
        isEditing() {
            return this.transactionId !== null;
        },
        walletOptions() {
            return this.wallets.map((wallet) => ({
                id: wallet.id,
                display: wallet.name,
            }));
        },
        originDivisionsOptions() {
            return this.originDivisions.map((division) => ({
                id: division.id,
                display: division.name,
            }));
        },
        destinationDivisionsOptions() {
            return this.destinationDivisions.map((division) => ({
                id: division.id,
                display: division.name,
            }));
        },
    },
    async mounted() {
        this.transactionId = this.$route.params.id || null;
        await this.loadWallets();
        if (this.isEditing) {
            await this.loadTransactionData();
        }
    },
    methods: {
        async loadTransactionData() {
            try {
                this.isLoading = true;
                const response = await api.get(`wallet/transactions/${this.transactionId}/`);
                const transaction = response.data;

                this.form = {
                    type: transaction.origin ? 'transfer' : transaction.balance.amount < 0 ? 'expense' : 'income',
                    name: transaction.name || '',
                    balance: {
                        amount: Math.abs(transaction.balance.amount) || 0,
                        currency: transaction.balance.currency || null,
                    },
                    date: transaction.date ? new Date(transaction.date).toISOString().split('T')[0] : null,
                    notes: transaction.notes || '',
                    origin: transaction.origin || null,
                    destination: transaction.destination || null,
                };

                if (this.form.origin) {
                    this.form.originWallet = await this.resolveWalletFromDivision(this.form.origin);
                    await this.loadOriginDivisions(this.form.originWallet);
                }

                if (this.form.destination) {
                    this.form.destinationWallet = await this.resolveWalletFromDivision(this.form.destination);
                    await this.loadDestinationDivisions(this.form.destinationWallet);
                }
            } catch (error) {
                console.error('Error loading transaction data:', error);
                alert('Failed to load transaction data. Please try again.');
                this.$router.push({ name: 'WalletDashboard' });
            } finally {
                this.isLoading = false;
            }
        },
        async resolveWalletFromDivision(divisionId) {
            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                return response.data.wallet;
            } catch (error) {
                console.error('Error resolving wallet from division:', error);
                return null;
            }
        },
        async loadWallets() {
            try {
                this.isLoading = true;
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results || [];
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadOriginDivisions(walletId) {
            try {
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.originDivisions = response.data.results || [];
            } catch (error) {
                console.error('Error loading origin divisions:', error);
            }
        },
        async loadDestinationDivisions(walletId) {
            try {
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.destinationDivisions = response.data.results || [];

                if (!this.isEditing) {
                    const divisionId = this.$route.query.division;
                    if (divisionId) {
                        this.setDivisionAsDestination(divisionId);
                    }
                }
            } catch (error) {
                console.error('Error loading destination divisions:', error);
            }
        },
        async setDivisionAsDestination(divisionId) {
            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                if (response.data) {
                    this.form.destinationWallet = response.data.wallet;
                    await this.loadDestinationDivisions(response.data.wallet);
                    this.form.destination = divisionId;
                }
            } catch (error) {
                console.warn('Division not found or error occurred while setting destination:', error);
            }
        },
        async submitTransaction() {
            try {
                this.isLoading = true;

                // Format date and days_of_week
                const formattedDate = this.form.date
                    ? new Date(this.form.date).toISOString().split('Z')[0]
                    : null;

                const data = {
                    ...this.form,
                    date: formattedDate,
                    //days_of_week: this.form.days_of_week.map((day) => (day ? 1 : 0)),
                };

                data.balance.amount = data.type === 'expense' ? -data.balance.amount : data.balance.amount;

                if (data.type !== 'transfer') {
                    data.origin = null;
                }

                if (!this.isRecurrent) {
                    delete data.repeat_until;
                    delete data.frequency;
                    delete data.frequency_number;
                    delete data.days_of_week;
                }

                const apiPath = this.isRecurrent
                    ? 'wallet/recurrent-transactions/'
                    : 'wallet/transactions/';

                const response = this.isEditing
                    ? await api.put(`${apiPath}${this.transactionId}/`, data)
                    : await api.post(apiPath, data);

                this.$router.push({ name: 'Transaction', params: { id: response.data.id } });
            } catch (error) {
                console.error('Error submitting transaction:', error);
                alert('An error occurred. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        toggleDay(index) {
            this.form.days_of_week[index] = !this.form.days_of_week[index];
        },
    },
};
</script>

<style scoped>
.add-transaction-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 1.8rem;
    color: #2b6cb0;
}

.transaction-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}


.row > * {
    flex: 1;
}

.form-actions {
    text-align: center;
}

.toggle-recurrent {
    text-align: center;
    margin-bottom: 10px;
}

.recurrent-fields {
    background-color: #edf2f7;
    padding: 15px;
    border-radius: 8px;
    margin-top: 10px;
}

.days-of-week {
    display: flex;
    justify-content: space-around;
}

.days-of-week button {
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #e2e8f0;
    color: #2d3748;
    transition: background-color 0.3s;
}

.days-of-week button.active {
    background-color: #4299e1;
    color: white;
}

.days-of-week button:hover {
    background-color: #2b6cb0;
    color: white;
}

.submit-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 1.8rem;
    color: #2b6cb0;
    text-align: center;
    flex-grow: 1;
}

.recurrent-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    right: 0;
}

.recurrent-label {
    font-size: 0.9rem;
    color: #4a5568;
    font-weight: bold;
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e2e8f0;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}

.slider:before {
    position: absolute;
    content: '';
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

input:checked + .slider {
    background-color: #48bb78;
}

input:checked + .slider:before {
    transform: translateX(20px);
}

</style>
