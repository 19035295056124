<template>
  <BaseLayout>
    <!-- Hero Section -->
    <section class="hero animated">
      <div class="row animated">
        <h1>Welcome to</h1>
        <img :src="logoUrl" alt="Bluejag Logo" class="logo animated"/>
      </div>
      <p class="animated">Your gateway to advanced tools in Finance, AI, Organizing, and more!</p>
      <button @click="exploreModules" class="animated">Explore Modules</button>
    </section>

    <!-- Modules Section -->
    <section class="modules">
      <ModuleCard
        v-for="appData in appsData"
        :key="appData.id"
        :module="appData"
        class="module-card animated"
      />
    </section>
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import ModuleCard from '@/views/HomePage/ModuleCard.vue';

const appsData = {
  'life': {
    name: 'Life',
    description: 'Organize tasks, projects, and events.',
    icon: 'bi bi-journal-check',
    dashboardPage: ''
  },
  'wallet': {
    name: 'Wallet',
    description: 'Manage your finances easily.',
    icon: 'bi bi-wallet2',
    dashboardPage: 'WalletDashboard'
  },
  'ai': {
    name: 'AI',
    description: 'Advanced AI tools for analysis.',
    icon: 'bi bi-cpu',
    dashboardPage: ''
  },
  'tracker': {
    name: 'Tracker',
    description: 'Track your activities and progress.',
    icon: 'bi bi-card-checklist',
    dashboardPage: ''
  }
}

export default {
  name: 'HomePage',
  components: {
    BaseLayout,
    ModuleCard
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    appsData() {
      return Object.keys(appsData).map(key => ({
        id: key,
        ...appsData[key]
      }))
    },
    currentPageName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
    };
  },
};
</script>

<style scoped>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  animation: fadeInUp 0.7s ease-out;
}

/* Home Page Styling */
.home-page {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f2f2f2; /* Light gray background */
  color: #1E3A8A; /* Blue text color for contrast */
}

.logo {
  height: 60px;
  border-radius: 30px;
}

.hero {
  text-align: center;
  margin-bottom: 40px;
}

.hero h1 {
  font-size: 3rem;
  color: #007BFF; /* Clearer blue for the header */
}

.hero button {
  background-color: #007BFF; /* Clear blue for the button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.hero button:hover {
  background-color: #0056b3;
}

.modules {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 40px 0;
  gap: 20px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
</style>
