// src/services/api.js

import axios from 'axios';

// Create an Axios instance
const api = axios.create({
  baseURL: 'https://bluejag.industries/api/',
  headers: {
    'Content-Type': 'application/json', // Ensures the content type is set correctly
  }
});

// Add a request interceptor to include the token in every request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken'); // Get token from local storage
    const testMode = localStorage.getItem('testMode') === 'true'; // Get test mode from local storage
    if (token) {
      config.headers['Authorization'] = `Token ${token}`; // Add the token to Authorization header
    }
    if (testMode) {
      config.headers['Test-Mode'] = 'true'; // Add the test mode header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to clean up `/api/api/` in `next` URLs
api.interceptors.response.use(
  (response) => {
    // Check if the response has a `next` field in its data and clean it up
    if (response.data && response.data.next) {
      response.data.next = response.data.next.replace('/api/api/', '/api/');
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
