<template>
    <BaseLayout :user="user">
        <div class="divisions-page">
            <header class="page-header">
                <h1>Divisions</h1>
            </header>

            <!-- Wallet Selector -->
            <section class="control-section">
                <OptionSelector v-if="wallets.length > 0"
                    v-model="selectedWallet"
                    title="Wallet"
                    :options="walletOptions"
                    @optionSelected="handleWalletChange"
                    style="position: relative; top: -24px"
                />
                <button class="add-division-button" @click="goToAddDivision">
                    <i class="bi bi-plus-circle"></i> Add Division
                </button>
            </section>

            <!-- Divisions List -->
            <section class="divisions-list">
                <h2>All Divisions</h2>
                <div v-if="isLoading">Loading divisions...</div>
                <div v-else-if="divisions.length === 0">No divisions found.</div>
                <router-link :to="{ name: 'Division', params: { id: division.id } }"
                    class="division-card no-underline"
                    v-for="division in divisions"
                    :key="division.id"
                >
                    <div class="division-info">
                        <h3>{{ division.name }}</h3>
                        <p>
                            Balance: ${{ formatAmount(division.balance.amount) }} 
                            {{ division.balance.currency }}
                        </p>
                    </div>
                </router-link>
                <button v-if="nextPageUrl" class="load-more-button" @click="loadMoreDivisions">
                    Load More Divisions
                </button>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';
import OptionSelector from '@/components/OptionSelector.vue';

export default {
    name: 'DivisionsView',
    components: {
        BaseLayout,
        OptionSelector,
    },
    data() {
        return {
            divisions: [],
            wallets: [],
            selectedWallet: null,
            isLoading: true,
            nextPageUrl: null, // Stores the next page URL for pagination
        };
    },
    computed: {
        walletOptions() {
            return this.wallets.map((wallet) => ({
                id: wallet.id,
                display: wallet.name,
            }));
        },
    },
    async mounted() {
        await this.loadWallets();
    },
    methods: {
        async loadWallets() {
            try {
                this.isLoading = true;
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results || [];
                this.selectedWallet = this.wallets.length > 0 ? this.wallets[0].id : null;

                // Load divisions for the selected wallet
                if (this.selectedWallet) {
                    await this.loadDivisions(this.selectedWallet);
                }
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadDivisions(walletId) {
            try {
                this.isLoading = true;
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.divisions = response.data.results || [];
                this.nextPageUrl = response.data.next;
            } catch (error) {
                console.error('Error loading divisions:', error);
                alert('Failed to load divisions. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadMoreDivisions() {
            if (!this.nextPageUrl) return;

            try {
                this.isLoading = true;

                // Parse the nextPageUrl
                const nextUrl = new URL(this.nextPageUrl, window.location.origin);

                // Ensure the URL uses the current domain and protocol
                nextUrl.protocol = window.location.protocol;
                nextUrl.host = window.location.host;

                // Make the request using the updated URL
                const response = await api.get(nextUrl.toString());

                // Append new divisions to the existing list
                this.divisions = [...this.divisions, ...response.data.results];
                this.nextPageUrl = response.data.next;
                
            } catch (error) {
                console.error('Error loading more divisions:', error);
                alert('Failed to load more divisions. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        handleWalletChange(walletId) {
            this.selectedWallet = walletId;
            this.divisions = []; // Clear current divisions
            this.nextDivisionsPage = null; // Reset pagination
            this.hasMoreDivisions = false;
            this.loadDivisions(walletId);
        },
        goToAddDivision() {
            this.$router.push({ name: 'NewDivision' });
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(amount / 100); // Add thousands separator
        },
    },
};
</script>

<style scoped>
.divisions-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.control-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.add-division-button {
    background-color: #48bb78;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-division-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.divisions-list {
    margin-top: 30px;
}

.divisions-list h2 {
    font-size: 1.5rem;
    color: #2d3748;
    margin-bottom: 20px;
}

.division-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 15px 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none; /* Removes underline */
    color: inherit; /* Ensures text inherits color */
}

.division-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.division-info h3 {
    font-size: 1.2rem;
    color: #2b6cb0;
    margin-bottom: 5px;
}

.division-info p {
    font-size: 1rem;
    color: #4a5568;
}

.load-more-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #2b6cb0;
}
</style>