<template>
    <BaseLayout :user="user">
        <div class="transaction-details-page">
            <header class="page-header">
                <h1>Transaction Details</h1>
            </header>

            <section v-if="isLoading" class="loading-section">
                Loading transaction details...
            </section>

            <section v-else class="transaction-details-section">
                <div class="detail-card">
                    <h2>{{ transaction.name || "Unnamed Transaction" }}</h2>
                    <p>
                        <span class="balance-icon" :class="getTransactionClass(transaction)">
                            <i
                                v-if="transaction.origin"
                                class="bi bi-arrow-left-right"
                            ></i>
                            <i
                                v-else-if="transaction.balance.amount > 0"
                                class="bi bi-arrow-up-circle"
                            ></i>
                            <i
                                v-else-if="transaction.balance.amount < 0"
                                class="bi bi-arrow-down-circle"
                            ></i>
                        </span>
                        <strong>Amount:</strong>
                        ${{ formatAmount(transaction.balance.amount) }} 
                        {{ transaction.balance.currency }}
                    </p>
                    <p>
                        <strong>Date:</strong> 
                        {{ formatDate(transaction.date) || "No date provided" }}
                    </p>
                    <p>
                        <strong>Notes:</strong> 
                        {{ transaction.notes || "No notes" }}
                    </p>
                    <p v-if="originDivisionName">
                        <strong>Origin Division:</strong> 
                        {{ originDivisionName }}
                    </p>
                    <p v-if="destinationDivisionName">
                        <strong>{{ transaction.origin ? 'Destination ' : '' }}Division:</strong> 
                        {{ destinationDivisionName }}
                    </p>
                </div>
                <div class="action-buttons">
                    <button class="back-button" @click="goBack">Back to Transactions</button>
                    <button class="edit-button" @click="editTransaction">Edit Transaction</button>
                    <button class="delete-button" @click="deleteTransaction">Delete Transaction</button>
                </div>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';

export default {
    name: 'TransactionDetailsView',
    components: {
        BaseLayout,
    },
    data() {
        return {
            transaction: null,
            isLoading: true,
            divisionCache: {}, // Cache for fetched divisions
            originDivisionName: null, // Resolved origin division name
            destinationDivisionName: null, // Resolved destination division name
        };
    },
    async mounted() {
        await this.loadTransaction();
    },
    methods: {
        async loadTransaction() {
            try {
                const transactionId = this.$route.params.id;
                const response = await api.get(`wallet/transactions/${transactionId}/`);
                this.transaction = response.data;

                if (this.transaction.origin) {
                    this.originDivisionName = await this.getDivisionName(this.transaction.origin);
                }
                if (this.transaction.destination) {
                    this.destinationDivisionName = await this.getDivisionName(this.transaction.destination);
                }
            } catch (error) {
                console.error('Error loading transaction details:', error);
                alert('Failed to load transaction details. Please try again.');
                this.$router.push({ name: 'Transactions' });
            } finally {
                this.isLoading = false;
            }
        },
        async getDivisionName(divisionId) {
            if (this.divisionCache[divisionId]) {
                return this.divisionCache[divisionId];
            }

            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                const divisionName = response.data.name;
                this.divisionCache[divisionId] = divisionName; // Cache the name
                return divisionName;
            } catch (error) {
                console.error(`Error loading division ${divisionId}:`, error);
                return 'Unknown Division';
            }
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(Math.abs(amount) / 100); // Add thousands separator
        },
        formatDate(date) {
            if (!date) return null;
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        },
        getTransactionClass(transaction) {
            if (transaction.origin) return 'transfer';
            if (transaction.balance.amount > 0) return 'positive';
            if (transaction.balance.amount < 0) return 'negative';
            return 'neutral';
        },
        goBack() {
            this.$router.push({ name: 'Transactions' });
        },
        editTransaction() {
            this.$router.push({ name: 'EditTransaction', params: { id: this.transaction.id } });
        },
        async deleteTransaction() {
            if (!confirm("Are you sure you want to delete this transaction?")) {
                return;
            }
            try {
                this.isLoading = true;
                const transactionId = this.$route.params.id;
                await api.delete(`wallet/transactions/${transactionId}/`);
                this.$router.push({ name: 'Transactions' }); // Redirect after deletion
            } catch (error) {
                console.error('Error deleting transaction:', error);
                alert('Failed to delete transaction. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.transaction-details-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.loading-section {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
}

.transaction-details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.detail-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detail-card h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.detail-card p {
    font-size: 1rem;
    color: #4a5568;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78; /* Green for positive balances */
}

.negative {
    color: #e53e3e; /* Red for negative balances */
}

.transfer {
    color: #4299e1; /* Blue for transfers */
}

.neutral {
    display: none; /* No icon for zero balances */
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.back-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.edit-button {
    background-color: #48bb78;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.delete-button {
    background-color: #e53e3e;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-button:hover {
    background-color: #c53030;
    transform: translateY(-2px);
}
</style>