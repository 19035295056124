<template>
  <router-link class="module-card" :to="{ name: module.dashboardPage }">
    <div class="title-section">
      <i :class=module.icon></i>
      <h2>{{ module.name }}</h2>
    </div>
    <p>{{ module.description }}</p>
  </router-link>
</template>

<script>

export default {
  name: 'ModuleCard',
  props: {
    module: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.module-card {
  width: 250px;
  padding: 20px;
  text-align: center;
  background-color: white; /* White background for the cards */
  color: #007BFF; /* Clear blue text for the modules */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.module-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.module-card .icon {
  margin-bottom: 15px;
}

.module-card img {
  width: 50px; /* Adjust the size of the module icons */
  height: 50px;
}

.module-card h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.module-card p {
  font-size: 1rem;
  color: #555; /* Gray text for descriptions */
}

.title-section {
  display: flex; /* Use Flexbox for alignment */
  justify-content: center;
  align-items: center; /* Vertically center all items */
  gap: 10px; /* Space between icon and text */
}

.title-section i {
  font-size: 1.5rem; /* Adjust the icon size as needed */
}

.title-section h2 {
  margin: 0; /* Remove any default margin */
}

</style>
