<template>
    <div
        class="indicator-button"
        :class="{ 'indicator-on': isChecked }"
        @click="toggle"
        role="button"
        :aria-pressed="isChecked"
        tabindex="0"
        :style="{ backgroundColor: isChecked ? activeColor : inactiveColor }"
    >
        <div
            class="indicator-light"
            :style="{ backgroundColor: isChecked ? lightActiveColor : lightInactiveColor }"
        ></div>
        <span class="indicator-text">{{ text }}</span>
    </div>
</template>

<script>
export default {
    name: "IndicatorButton",
    props: {
        modelValue: {
            type: Boolean,
            default: false, // Default state (off)
        },
        text: {
            type: String,
            default: "Test Mode", // Default text
        },
        activeColor: {
            type: String,
            default: "#48bb78", // Default green when on
        },
        inactiveColor: {
            type: String,
            default: "#e2e8f0", // Default neutral gray when off
        },
        lightActiveColor: {
            type: String,
            default: "#ffffff", // Default white light when on
        },
        lightInactiveColor: {
            type: String,
            default: "#4a5568", // Default gray light when off
        },
    },
    computed: {
        isChecked() {
            return this.modelValue;
        },
    },
    methods: {
        toggle() {
            this.$emit("update:modelValue", !this.modelValue); // Emit the updated value
        },
    },
};
</script>

<style scoped>
.indicator-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 150px; /* Adjust width as needed */
    padding: 8px 12px; /* Add padding for comfortable click area */
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.indicator-button.indicator-on {
    box-shadow: 0 0 8px rgba(72, 187, 120, 0.5); /* Subtle glow effect when on */
}

.indicator-light {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.indicator-button.indicator-on .indicator-light {
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.8); /* Glow effect when on */
}

.indicator-text {
    font-size: 0.875rem;
    font-weight: bold;
    color: #4a5568; /* Neutral text color when off */
    transition: color 0.3s ease;
}

.indicator-button.indicator-on .indicator-text {
    color: white; /* Bright white text when on */
}
</style>