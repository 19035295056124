import { createStore } from 'vuex';
import api from '@/services/api.js';

export default createStore({
  state: {
    user: null,
    userApps: [],
    token: localStorage.getItem('authToken') || '',  // Retrieve token from localStorage or set to empty string if null
    selectedApp: localStorage.getItem('selectedApp'),
    testMode: localStorage.getItem('testMode') === 'true' || false,
    adminMode: localStorage.getItem('adminMode') === 'true' || false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem('authToken');
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserApps(state, apps) {
      state.userApps = apps;
    },
    setSelectedApp(state, app) {
      state.selectedApp = app;
      if (app) {
        localStorage.setItem('selectedApp', app);
      } else {
        localStorage.removeItem('selectedApp');
      }
    },
    setTestMode(state, mode) {
      state.testMode = mode;
      localStorage.setItem('testMode', mode);
    },
    toggleTestMode(state) {
      state.testMode = !state.testMode;
      localStorage.setItem('testMode', state.testMode);
    },
    setAdminMode(state, mode) {
      state.adminMode = mode;
      localStorage.setItem('adminMode', mode);
    },
    toggleAdminMode(state) {
      state.adminMode = !state.adminMode;
      localStorage.setItem('adminMode', state.adminMode);
    }
  },
  actions: {
    async checkAuthStatus({ commit, dispatch }) {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          const response = await api.get('profile/');  // Adjust this API call as needed
          commit('setUser', response.data);
          await dispatch('getUserApps');
        } catch (error) {
          if (error.response && error.response.status === 404) {
            commit('clearUser');
          }
        }
      } else {
        commit('clearUser');
      }
    },
    logout({ commit }) {
      localStorage.removeItem('authToken');
      commit('clearUser');
    },
    async getUserApps({ commit, state }) {
      try {
        const response = await api.get('user-apps/');
        commit('setUserApps', response.data);  // Commit user apps to the state
        if (!state.selectedApp || !response.data.includes(state.selectedApp)) {
          if (response.data.length > 0) {
            commit('setSelectedApp', response.data[0]);  // Set the first app as the selected app if none is selected or if the selected app is not in the response data
          } else {
            commit('setSelectedApp', null);  // Set the selected app to null if there are no apps
          }
        }
      } catch (error) {
        console.error('Failed to fetch user apps:', error);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.user,  // Return true if user is authenticated
    getUser: (state) => state.user,
  }
});