<template>
    <BaseLayout :user="user">
        <div class="add-wallet-page">
            <header class="page-header">
                <h1>{{ isEditing ? 'Edit Wallet' : 'Add Wallet' }}</h1>
            </header>

            <form class="wallet-form" @submit.prevent="submitWallet">
                <div class="row">
                    <FieldErrorWrapper :error="errors.name">
                        <TextField v-model="form.name" title="Wallet Name" :required="false"></TextField>
                    </FieldErrorWrapper>
                    <FieldErrorWrapper :error="errors.currency">
                        <OptionSelector
                            v-model="form.currency"
                            title="Currency"
                            :options="currencyOptions"
                            style="position: relative; top: -24px"
                        />
                    </FieldErrorWrapper>
                </div>

                <FieldErrorWrapper :error="errors.notes">
                    <TextArea v-model="form.notes" title="Notes"></TextArea>
                </FieldErrorWrapper>

                <!-- Submit Button -->
                <div class="form-actions">
                    <button class="submit-button" type="submit" :disabled="isLoading">
                        <i class="bi bi-plus-circle"></i>
                        {{ isLoading ? "Submitting..." : isEditing ? "Save Wallet" : "Add Wallet" }}
                    </button>
                </div>
            </form>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';
import TextField from '@/components/TextField/TextField.vue';
import TextArea from '@/components/TextArea.vue';
import OptionSelector from '@/components/OptionSelector.vue';
import FieldErrorWrapper from '@/components/FieldErrorWrapper.vue';

export default {
    name: 'EditWalletPage',
    components: {
        BaseLayout,
        TextField,
        TextArea,
        OptionSelector,
        FieldErrorWrapper,
    },
    data() {
        return {
            form: {
                name: '',
                currency: null,
                notes: '',
            },
            isLoading: false,
            errors: {},

            currencyOptions: [
                { id: 'MXN', display: 'MXN' },
                { id: 'USD', display: 'USD' },
                { id: 'EUR', display: 'EUR' },
                { id: 'GBP', display: 'GBP' },
            ],
            walletId: null, // To track if we're editing a wallet
        };
    },
    computed: {
        isEditing() {
            return this.walletId !== null; // If walletId is set, we're editing
        },
    },
    async mounted() {
        this.walletId = this.$route.params.id || null; // Get walletId from route params
        if (this.isEditing) {
            await this.loadWalletData();
        }
    },
    methods: {
        async loadWalletData() {
            try {
                this.isLoading = true;
                const response = await api.get(`wallet/wallets/${this.walletId}/`);
                const wallet = response.data;
                this.form = {
                    name: wallet.name || '',
                    currency: wallet.balance.currency || null,
                    notes: wallet.notes || '',
                };
            } catch (error) {
                console.error('Error loading wallet data:', error);
                alert('Failed to load wallet data. Please try again.');
                this.$router.push({ name: 'WalletDashboard' }); // Redirect if wallet cannot be loaded
            } finally {
                this.isLoading = false;
            }
        },
        async submitWallet() {
            try {
                this.isLoading = true;

                // Prepare the data
                const data = { ...this.form };

                var response;

                // Send the data to the backend
                if (this.isEditing) {
                    response = await api.put(`wallet/wallets/${this.walletId}/`, data);
                } else {
                    response = await api.post('wallet/wallets/', data);
                }

                // Handle the response
                this.$router.push({ name: 'Wallet', params: { id: response.data.id } });
            } catch (error) {
                const errorResponse = error.response?.data || {};
                this.errors = errorResponse.errors || {};
                //alert(errorResponse.message || 'An error occurred. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.add-wallet-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 1.8rem;
    color: #2b6cb0;
}

.wallet-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.row > * {
    flex: 1; /* Distributes the available space evenly */
}

.form-actions {
    text-align: center;
}

.submit-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}
</style>