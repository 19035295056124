<template>
    <BaseLayout :user="user">
        <div class="scheduled-transactions-page">
            <header class="page-header">
                <h1>Scheduled Transactions</h1>
            </header>

            <!-- Filter Section -->
            <section class="filters-section">
                <div class="row">
                    <OptionSelector
                        v-model="selectedWallet"
                        title="Select Wallet"
                        :options="walletOptions"
                        @optionSelected="handleWalletChange"
                    />
                    <OptionSelector
                        v-if="selectedWallet && divisions.length > 0"
                        v-model="selectedDivision"
                        title="Select Division"
                        :options="divisionOptions"
                        @optionSelected="loadScheduledTransactions"
                    />
                    <button class="add-scheduled-transaction-button" @click="goToAddScheduledTransaction">
                        <i class="bi bi-plus-circle"></i> Add Scheduled Transaction
                    </button>
                </div>
            </section>

            <!-- Scheduled Transactions List -->
            <section class="scheduled-transactions-list">
                <h2>All Scheduled Transactions</h2>
                <div v-if="isLoading && scheduledTransactions.length === 0">Loading scheduled transactions...</div>
                <div v-else-if="scheduledTransactions.length === 0">No scheduled transactions found.</div>
                <router-link
                    :to="{ name: 'ScheduledTransaction', params: { id: transaction.id } }"
                    class="scheduled-transaction-card"
                    v-for="transaction in scheduledTransactions"
                    :key="transaction.id"
                >
                    <div class="scheduled-transaction-info">
                        <h3>{{ transaction.name || "Unnamed Transaction" }}</h3>
                        <p>
                            <span class="balance-icon" :class="getBalanceClass(transaction)">
                                <i v-if="transaction.origin" class="bi bi-arrow-left-right transfer"></i>
                                <i v-else-if="transaction.balance.amount > 0" class="bi bi-arrow-up-circle positive"></i>
                                <i v-else-if="transaction.balance.amount < 0" class="bi bi-arrow-down-circle negative"></i>
                            </span>
                            Amount: ${{ formatAmount(Math.abs(transaction.balance.amount)) }}
                            {{ transaction.balance.currency }}
                        </p>
                        <p v-if="transaction.date">
                            Scheduled Date: {{ formatDate(transaction.date) }}
                        </p>
                        <p>Notes: {{ transaction.notes || "No notes" }}</p>
                        <p v-if="transaction.origin">
                            <strong>Origin:</strong> {{ divisionCache[transaction.origin] || "Loading..." }}
                        </p>
                        <p v-if="transaction.destination">
                            <strong>Destination:</strong> {{ divisionCache[transaction.destination] || "Loading..." }}
                        </p>
                    </div>
                </router-link>
                <button v-if="nextPageUrl" class="load-more-button" @click="loadMoreScheduledTransactions">
                    Load More Scheduled Transactions
                </button>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';
import OptionSelector from '@/components/OptionSelector.vue';

export default {
    name: 'ScheduledTransactionsView',
    components: {
        BaseLayout,
        OptionSelector,
    },
    data() {
        return {
            wallets: [],
            divisions: [],
            scheduledTransactions: [],
            selectedWallet: 'all',
            selectedDivision: 'all',
            divisionCache: {}, // Cache for division names
            isLoading: false,
            nextPageUrl: null, // URL for the next page of transactions
        };
    },
    computed: {
        walletOptions() {
            return [
                { id: 'all', display: 'All Wallets' },
                ...this.wallets.map((wallet) => ({
                    id: wallet.id,
                    display: wallet.name,
                })),
            ];
        },
        divisionOptions() {
            return [
                { id: 'all', display: 'All Divisions' },
                ...this.divisions.map((division) => ({
                    id: division.id,
                    display: division.name,
                })),
            ];
        },
    },
    async mounted() {
        await this.loadWallets();
        await this.loadScheduledTransactions();
    },
    methods: {
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(amount / 100);
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('en-US', options);
        },
        getBalanceClass(transaction) {
            if (transaction.origin) {
                return transaction.origin === this.selectedDivision ? 'negative' : 'positive';
            }
            return transaction.balance.amount > 0 ? 'positive' : 'negative';
        },
        async getDivisionName(divisionId) {
            if (this.divisionCache[divisionId]) {
                return this.divisionCache[divisionId];
            }

            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                const divisionName = response.data.name;
                this.divisionCache[divisionId] = divisionName;
                return divisionName;
            } catch (error) {
                console.error(`Error loading division ${divisionId}:`, error);
                return 'Unknown Division';
            }
        },
        async loadWallets() {
            try {
                this.isLoading = true;
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results || [];
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadDivisions(walletId) {
            if (walletId === 'all') {
                this.divisions = [];
                return;
            }

            try {
                this.isLoading = true;
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.divisions = response.data.results || [];
            } catch (error) {
                console.error('Error loading divisions:', error);
                alert('Failed to load divisions. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadScheduledTransactions() {
            try {
                this.isLoading = true;

                let url = 'wallet/scheduled-transactions/';
                const params = {
                    pending: true,
                };

                if (this.selectedWallet !== 'all') {
                    params.wallet = this.selectedWallet;
                }

                if (this.selectedDivision !== 'all' && this.selectedWallet !== 'all') {
                    params.division = this.selectedDivision;
                }

                const response = await api.get(url, { params });
                this.scheduledTransactions = response.data.results || [];
                this.nextPageUrl = response.data.next;

                for (const transaction of this.scheduledTransactions) {
                    if (transaction.origin && !this.divisionCache[transaction.origin]) {
                        this.divisionCache[transaction.origin] = await this.getDivisionName(transaction.origin);
                    }
                    if (transaction.destination && !this.divisionCache[transaction.destination]) {
                        this.divisionCache[transaction.destination] = await this.getDivisionName(transaction.destination);
                    }
                }
            } catch (error) {
                console.error('Error loading scheduled transactions:', error);
                alert('Failed to load scheduled transactions. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async loadMoreScheduledTransactions() {
            if (!this.nextPageUrl) return;

            try {
                this.isLoading = true;

                // Parse the nextPageUrl
                const nextUrl = new URL(this.nextPageUrl, window.location.origin);

                // Ensure the URL uses the current domain and protocol
                nextUrl.protocol = window.location.protocol;
                nextUrl.host = window.location.host;

                // Make the request using the updated URL
                const response = await api.get(nextUrl.toString());

                // Append new scheduled transactions to the existing list
                this.scheduledTransactions = [...this.scheduledTransactions, ...response.data.results];
                this.nextPageUrl = response.data.next;

                // Cache division names for new scheduled transactions
                for (const transaction of response.data.results) {
                    if (transaction.origin && !this.divisionCache[transaction.origin]) {
                        this.divisionCache[transaction.origin] = await this.getDivisionName(transaction.origin);
                    }
                    if (transaction.destination && !this.divisionCache[transaction.destination]) {
                        this.divisionCache[transaction.destination] = await this.getDivisionName(transaction.destination);
                    }
                }
            } catch (error) {
                console.error('Error loading more scheduled transactions:', error);
                alert('Failed to load more scheduled transactions. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        handleWalletChange(walletId) {
            this.selectedWallet = walletId;
            this.selectedDivision = 'all';
            if (walletId === 'all') {
                this.divisions = [];
                this.loadScheduledTransactions();
            } else {
                this.loadDivisions(walletId).then(() => this.loadScheduledTransactions());
            }
        },
        goToAddScheduledTransaction() {
            this.$router.push({ name: 'NewScheduledTransaction' });
        },
    },
};
</script>

<style scoped>
.scheduled-transactions-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.filters-section {
    margin-bottom: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.add-scheduled-transaction-button {
    background-color: #48bb78;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 8px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-scheduled-transaction-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.scheduled-transactions-list {
    margin-top: 30px;
}

.scheduled-transactions-list h2 {
    font-size: 1.5rem;
    color: #2d3748;
    margin-bottom: 20px;
}

.scheduled-transaction-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 15px 20px;
    margin-bottom: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
}

.scheduled-transaction-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.scheduled-transaction-info h3 {
    font-size: 1.2rem;
    color: #2b6cb0;
}

.scheduled-transaction-info p {
    font-size: 1rem;
    color: #4a5568;
    display: flex;
    align-items: center;
    gap: 5px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78;
}

.negative {
    color: #e53e3e;
}

.transfer {
    color: #4299e1;
}

.load-more-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.load-more-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}
</style>