<template>
  <BaseLayout>
    <div class="login-page">
      <div class="login-container">
        <div class="row">
          <h2 class="login-title">Login to</h2>
          <img :src="logoUrl" alt="Bluejag Logo" class="logo"/>
        </div>
        <form @submit.prevent="login" class="login-form">
          <TextField v-model="username" title="Username" :required="true"/>
          <TextField v-model="password" title="Password" :required="true" :type="'password'"/>
          <button type="submit" class="login-btn">Login</button>
        </form>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div class="options">
          <router-link to="/forgot-password" class="option-link">Forgot Password?</router-link>
          <router-link to="/signup" class="option-link">Sign Up</router-link>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import TextField from '@/components/TextField/TextField.vue';
import api from '@/services/api.js';

export default {
  name: 'LoginPage',
  components: {
    BaseLayout,
    TextField
  },
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
      username: '',
      password: '',
      errorMessage: '' // To store error messages
    };
  },
  methods: {
    async login() {
      try {
        const response = await api.post('auth-token/', {
          username: this.username,
          password: this.password
        });
        const token = response.data.token;
        localStorage.setItem('authToken', token); // Store the token in local storage
        this.errorMessage = ''; // Clear any previous error message on successful login
        this.$router.push({ name: 'HomePage' });
      } catch (error) {
        if (error.response && error.response.data && error.response.data.detail) {
          this.errorMessage = error.response.data.detail; // Use the error message from the backend
        } else {
          this.errorMessage = 'Login failed. Please try again.'; // Default error message
        }
      }
    }
  }
};
</script>

<style scoped>
/* General Page Styles */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  margin-top: 20px;
}

.login-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
  position: relative;
  animation: fadeInUp 0.5s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Login Title */
.login-title {
  font-size: 2rem;
  color: #007bff;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  height: 50px;
  border-radius: 30px;
}

/* Form Styles */
.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  position: relative;
  margin-bottom: 20px;
}

.login-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 15px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.login-btn:hover {
  background-color: #0056b3;
}

/* Error Message Styles */
.error-message {
  color: #ff4d4f;
  margin-top: 15px;
  font-size: 0.9rem;
}

/* Options (Forgot Password and Sign Up Links) */
.options {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.option-link {
  color: #007bff;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.option-link:hover {
  color: #0056b3;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  animation: fadeInDown 0.7s ease-in-out;
}
</style>