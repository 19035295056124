<template>
    <BaseLayout :user="user">
        <div class="connect-wallet-page">
            <header class="page-header">
                <h1>Connect to External Wallet</h1>
            </header>

            <section class="connect-wallet-section">
                <div class="instruction-card">
                    <h2>Enter Share Code</h2>
                    <p>
                        To connect to an external wallet, ask the wallet owner for the
                        <strong>Share Code</strong>. Enter it below and click
                        <strong>Connect</strong>.
                    </p>
                </div>

                <form @submit.prevent="connectWallet" class="connect-form">
                    <div class="form-group">
                        <label for="share-code">Share Code</label>
                        <input
                            type="text"
                            id="share-code"
                            v-model="shareCode"
                            placeholder="Enter the share code here"
                            class="form-input"
                            required
                        />
                    </div>
                    <button class="connect-button" type="submit" :disabled="isLoading">
                        <i v-if="isLoading" class="bi bi-arrow-repeat spinner"></i>
                        Connect
                    </button>
                </form>

                <div v-if="connectionStatus" :class="`status-message ${connectionStatus.type}`">
                    {{ connectionStatus.message }}
                </div>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout.vue";
import api from "@/services/api";

export default {
    name: "ConnectWalletPage",
    components: {
        BaseLayout,
    },
    data() {
        return {
            shareCode: "",
            isLoading: false,
            connectionStatus: null, // Stores status messages (success or error)
        };
    },
    methods: {
        async connectWallet() {
            if (!this.shareCode.trim()) {
                this.connectionStatus = {
                    type: "error",
                    message: "Please enter a valid share code.",
                };
                return;
            }

            try {
                this.isLoading = true;
                this.connectionStatus = null;

                // API call to connect the wallet using the share code
                const response = await api.post("wallet/wallets/connect/", {
                    share_code: this.shareCode.trim(),
                });

                this.connectionStatus = {
                    type: "success",
                    message: `Successfully connected to wallet: ${response.data.wallet_name}`,
                };

                // Optionally redirect to the connected wallet view
                this.$router.push({ name: "Wallet", params: { id: response.data.id } });
            } catch (error) {
                console.error("Error connecting to wallet:", error);
                this.connectionStatus = {
                    type: "error",
                    message: "Failed to connect to the wallet. Please check the share code and try again.",
                };
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.connect-wallet-page {
    font-family: "Inter", sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    text-align: center;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
    margin-bottom: 20px;
}

.connect-wallet-section {
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.instruction-card {
    background-color: #edf2f7;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.instruction-card h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
}

.instruction-card p {
    font-size: 1rem;
    color: #4a5568;
    line-height: 1.5;
}

.connect-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    text-align: left;
}

.form-group label {
    font-size: 1rem;
    color: #2d3748;
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

.form-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease;
}

.form-input:focus {
    border-color: #4299e1;
}

.connect-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.connect-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}

.connect-button:hover:enabled {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.status-message {
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 1rem;
    border-radius: 8px;
    text-align: center;
}

.status-message.success {
    background-color: #48bb78;
    color: white;
}

.status-message.error {
    background-color: #e53e3e;
    color: white;
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>