<template>
    <BaseLayout :user="user">
        <div class="scheduled-transaction-details-page">
            <header class="page-header">
                <button class="back-button" @click="goBack">
                    <i class="bi bi-arrow-left"></i> Back to Scheduled Transactions
                </button>
                <h1>Scheduled Transaction Details</h1>
            </header>

            <section v-if="isLoading" class="loading-section">
                Loading scheduled transaction details...
            </section>

            <section v-else class="scheduled-transaction-details-section">
                <div class="detail-card">
                    <h2>{{ transaction.name || "Unnamed Scheduled Transaction" }}</h2>
                    <p>
                        <span class="balance-icon" :class="getTransactionClass(transaction)">
                            <i v-if="transaction.origin" class="bi bi-arrow-left-right transfer"></i>
                            <i v-else-if="transaction.balance.amount > 0" class="bi bi-arrow-up-circle positive"></i>
                            <i v-else-if="transaction.balance.amount < 0" class="bi bi-arrow-down-circle negative"></i>
                        </span>
                        <span class="amount-text">
                            <strong>Amount:</strong>
                            ${{ formatAmount(transaction.balance.amount) }} 
                            {{ transaction.balance.currency }}
                        </span>
                    </p>
                    <p>
                        <strong>Type:</strong> 
                        {{ getTransactionType(transaction) }}
                    </p>
                    <p>
                        <strong>Scheduled Date:</strong> 
                        {{ formatDate(transaction.date) || "No date provided" }}
                    </p>
                    <p>
                        <strong>Notes:</strong> 
                        {{ transaction.notes || "No notes" }}
                    </p>
                    <p v-if="originDivisionName">
                        <strong>Origin Division:</strong> 
                        {{ originDivisionName }}
                    </p>
                    <p v-if="destinationDivisionName">
                        <strong>{{ transaction.origin ? 'Destination ' : '' }}Division:</strong> 
                        {{ destinationDivisionName }}
                    </p>
                </div>
                <div class="action-buttons">
                    <button class="edit-button" @click="editTransaction">Edit Scheduled Transaction</button>
                    <button class="delete-button" @click="deleteTransaction">Delete Scheduled Transaction</button>
                    <button 
                        v-if="!transaction.transaction" 
                        class="apply-button" 
                        @click="applyTransaction"
                    >
                        Apply Scheduled Transaction
                    </button>
                </div>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';

export default {
    name: 'ScheduledTransactionPage',
    components: {
        BaseLayout,
    },
    data() {
        return {
            transaction: null,
            isLoading: true,
            divisionCache: {}, // Cache for fetched divisions
            originDivisionName: null, // Resolved origin division name
            destinationDivisionName: null, // Resolved destination division name
        };
    },
    async mounted() {
        await this.loadTransaction();
    },
    methods: {
        async loadTransaction() {
            try {
                const transactionId = this.$route.params.id;
                const response = await api.get(`wallet/scheduled-transactions/${transactionId}/`);
                this.transaction = response.data;

                if (this.transaction.origin) {
                    this.originDivisionName = await this.getDivisionName(this.transaction.origin);
                }
                if (this.transaction.destination) {
                    this.destinationDivisionName = await this.getDivisionName(this.transaction.destination);
                }
            } catch (error) {
                console.error('Error loading scheduled transaction details:', error);
                alert('Failed to load scheduled transaction details. Please try again.');
                this.$router.push({ name: 'ScheduledTransactions' });
            } finally {
                this.isLoading = false;
            }
        },
        async getDivisionName(divisionId) {
            if (this.divisionCache[divisionId]) {
                return this.divisionCache[divisionId];
            }

            try {
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                const divisionName = response.data.name;
                this.divisionCache[divisionId] = divisionName; // Cache the name
                return divisionName;
            } catch (error) {
                console.error(`Error loading division ${divisionId}:`, error);
                return 'Unknown Division';
            }
        },
        async applyTransaction() {
            if (!confirm("Are you sure you want to apply this scheduled transaction?")) {
                return;
            }
            try {
                this.isLoading = true;
                const transactionId = this.$route.params.id;
                await api.post(`wallet/scheduled-transactions/${transactionId}/apply/`);
                this.$router.push({ name: 'Transactions' }); // Redirect to Transactions
            } catch (error) {
                console.error('Error applying scheduled transaction:', error);
                alert('Failed to apply scheduled transaction. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        getTransactionType(transaction) {
            if (transaction.origin) return 'Transfer';
            return transaction.balance.amount > 0 ? 'Income' : 'Expense';
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(Math.abs(amount) / 100); // Add thousands separator
        },
        formatDate(date) {
            if (!date) return null;
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
        },
        getTransactionClass(transaction) {
            if (transaction.origin) return 'transfer';
            if (transaction.balance.amount > 0) return 'positive';
            if (transaction.balance.amount < 0) return 'negative';
            return 'neutral';
        },
        goBack() {
            this.$router.push({ name: 'ScheduledTransactions' });
        },
        editTransaction() {
            this.$router.push({ name: 'EditScheduledTransaction', params: { id: this.transaction.id } });
        },
        async deleteTransaction() {
            if (!confirm("Are you sure you want to delete this scheduled transaction?")) {
                return;
            }
            try {
                this.isLoading = true;
                const transactionId = this.$route.params.id;
                await api.delete(`wallet/scheduled-transactions/${transactionId}/`);
                this.$router.push({ name: 'ScheduledTransactions' }); // Redirect after deletion
            } catch (error) {
                console.error('Error deleting scheduled transaction:', error);
                alert('Failed to delete scheduled transaction. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.scheduled-transaction-details-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}

.page-header .back-button {
    position: absolute;
    top: -50px;
    left: 0;
    background-color: transparent;
    color: #2b6cb0;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: color 0.3s ease, transform 0.3s ease;
}

.page-header .back-button:hover {
    color: #4299e1;
    transform: translateY(-2px);
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.loading-section {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
}

.scheduled-transaction-details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.detail-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detail-card h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.detail-card p {
    font-size: 1rem;
    color: #4a5568;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 10px; /* Add gap between icon and text */
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78;
}

.negative {
    color: #e53e3e;
}

.transfer {
    color: #4299e1;
}

.neutral {
    display: none;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.edit-button {
    background-color: #48bb78;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.delete-button {
    background-color: #e53e3e;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-button:hover {
    background-color: #c53030;
    transform: translateY(-2px);
}

.apply-button {
    background-color: #805ad5;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.apply-button:hover {
    background-color: #6b46c1;
    transform: translateY(-2px);
}
</style>