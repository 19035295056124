<template>
    <div :class="['field-wrapper', { 'has-error': hasError }]">
      <slot></slot>
      <p v-if="hasError && describe" class="error-message">{{ errorDisplay }}</p>
    </div>
</template>

<script>
export default {
    name: "FieldWrapper",
    props: {
        error: {
            type: String,
            default: null,
        },
        describe: {
            type: Boolean,
            default: true, // When true, display the error message
        },
    },
    computed: {
        hasError() {
            return this.error !== null;
        },
        errorDisplay() {
            // Return the error if it is not an array
            if (!Array.isArray(this.error)) {
                return this.error;
            }
            // Return the first element of the list if it is an array
            return this.error[0];
        },
    },
};
</script>

<style scoped>
.field-wrapper {
    margin-bottom: 0px;
    position: relative;
}

.field-wrapper.has-error {
    /* Add a red border to the field inside the wrapper */
    border: 1px solid #e53e3e;
    border-radius: 8px;
    padding: 5px;
}

.error-message {
    color: #e53e3e;
    font-size: 0.875rem;
    margin-top: 4px;
    text-align: center;
}
</style>