<template>
    <BaseLayout :user="user">
        <div class="dashboard-page">
            <!-- Header Section -->
            <header class="dashboard-header">
                <div class="balance-section">
                    <div class="balance-info">
                        <h1>Total Balance</h1>
                        <p class="balance-amount">${{ totalBalance }} MXN</p>
                        <p class="balance-change">
                            <i class="bi bi-arrow-up-circle"></i> ${{ balanceChange }} MXN in the last month
                        </p>
                        <div class="progress-bar">
                            <div class="progress" :style="{ width: `${budgetRemaining}%` }"></div>
                        </div>
                        <p class="budget-label">
                            Budget Remaining: 
                            <span>{{ animatedPercentage }}%</span>
                        </p>
                    </div>
                    <DropdownMenu
                        :options="wallets"
                        title="Active Wallet"
                        selectOptionText="Select a Wallet"
                        displayKey="name"
                        v-model="selectedWallet"
                    />
                    <div class="balance-chart">
                        <canvas ref="balanceChart"></canvas>
                    </div>
                </div>
            </header>

            <!-- Button to Wallets View -->
            <section class="navigate-wallets-section">
                <button class="wallets-button" @click="goToWallets">
                    <i class="bi bi-wallet2"></i> Go to Wallets
                </button>
            </section>

            <!-- Overview Section -->
            <section class="overview-section">
                <router-link
                    :to="{ name: section.linkedPage || $route.name }"
                    class="summary-card"
                    v-for="section in overviewSections"
                    :key="section.id"
                >
                    <div class="card-icon" :style="{ backgroundColor: section.color }">
                        <i :class="section.icon"></i>
                    </div>
                    <div class="card-content">
                        <h3>{{ section.title }}</h3>
                        <p>{{ section.description }}</p>
                        <div class="card-link">
                            <span>{{ section.actionText }}</span>
                        </div>
                    </div>
                </router-link>
            </section>

            <!-- Actions Section -->
            <section class="actions-section">
                <router-link :to="{ name: 'NewTransaction' }" class="action-button income no-underline">
                    <i class="bi bi-cash-stack"></i> Income
                </router-link>
                <router-link :to="{ name: 'NewTransaction' }" class="action-button transfer no-underline">
                    <i class="bi bi-arrow-left-right"></i> Transfer
                </router-link>
                <router-link :to="{ name: 'NewTransaction' }" class="action-button bill no-underline">
                    <i class="bi bi-file-earmark-medical"></i> Bill
                </router-link>
                <router-link :to="{ name: 'Divisions' }" class="action-button division no-underline">
                    <i class="bi bi-wallet2"></i> Division
                </router-link>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import { Chart, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js';
import api from '@/services/api';
import DropdownMenu from '@/components/DropdownMenu.vue';

export default {
    name: 'WalletDashboardPage',
    components: {
        BaseLayout,
        DropdownMenu,
    },
    data() {
        return {
            totalBalance: 271.0,
            balanceChange: 230.0,
            budgetRemaining: 34,
            animatedPercentage: 0, // For animated percentage
            selectedWallet: null, // Tracks the selected wallet
            wallets: [], // List of wallets
            overviewSections: [
                {
                    id: 1,
                    title: 'Recent Transactions',
                    description: 'View your latest financial activity.',
                    icon: 'bi bi-list-ul',
                    color: '#4299E1',
                    actionText: 'View Transactions',
                    linkedPage: 'Transactions',
                },
                {
                    id: 2,
                    title: 'Upcoming Transactions',
                    description: 'Stay on top of your upcoming payments.',
                    icon: 'bi bi-calendar-event',
                    color: '#E53E3E',
                    actionText: 'View Schedule',
                    linkedPage: 'ScheduledTransactions',
                },
                {
                    id: 3,
                    title: 'Proposed Transactions',
                    description: 'View your proposed transactions.',
                    icon: 'bi bi-file-earmark-medical',
                    color: '#F6AD55',
                    actionText: 'View Proposals',
                    linkedPage: 'ProposedTransactions',
                },
                {
                    id: 4,
                    title: 'Goals Overview',
                    description: 'Track your financial goals.',
                    icon: 'bi bi-flag',
                    color: '#38A169',
                    actionText: 'View Goals',
                },
            ],
        };
    },
    mounted() {
        this.fetchWallets();
        this.registerChartComponents();
        this.renderBalanceChart();
        this.animateProgressBar();
    },
    methods: {
        async fetchWallets() {
            try {
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results;
            } catch (error) {
                console.error('Error fetching wallets:', error);
                this.$router.push({ name: 'HomePage' });
            }
        },
        registerChartComponents() {
            Chart.register(DoughnutController, ArcElement, Tooltip, Legend);
        },
        renderBalanceChart() {
            new Chart(this.$refs.balanceChart, {
                type: 'doughnut',
                data: {
                    labels: ['Remaining Budget', 'Spent'],
                    datasets: [
                        {
                            data: [this.budgetRemaining, 100 - this.budgetRemaining],
                            backgroundColor: ['#48BB78', '#E53E3E'],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    animation: {
                        animateScale: true,
                        animateRotate: true,
                    },
                },
            });
        },
        animateProgressBar() {
            const duration = 1500;
            const step = (this.budgetRemaining / duration) * 10;
            let progress = 0;

            const interval = setInterval(() => {
                if (progress >= this.budgetRemaining) {
                    progress = this.budgetRemaining;
                    clearInterval(interval);
                }

                this.animatedPercentage = Math.round(progress);
                progress += step;
            }, 10);
        },
        goToWallets() {
            this.$router.push({ name: 'Wallets' });
        },
    },
};
</script>

<style scoped>
.dashboard-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
}

.dashboard-header {
    margin-bottom: 30px;
}

.balance-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.balance-info h1 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #2b6cb0;
}

.balance-amount {
    font-size: 2.8rem;
    color: #2c5282;
    margin-top: 10px;
    font-weight: bold;
}

.balance-change {
    color: #38a169;
    font-size: 1.1rem;
    margin-top: 10px;
}

.progress-bar {
    background-color: #edf2f7;
    border-radius: 8px;
    height: 12px;
    margin-top: 20px;
    overflow: hidden;
    position: relative;
}

.progress {
    background-color: #48bb78;
    height: 100%;
    transition: width 1.5s ease-in-out;
}

.budget-label {
    margin-top: 10px;
    font-weight: bold;
    font-size: 1rem;
    color: #718096;
}

.balance-chart {
    width: 180px;
    height: 180px;
}

.navigate-wallets-section {
    text-align: center;
    margin-bottom: 30px;
}

.wallets-button {
    padding: 10px 20px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.wallets-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.overview-section {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
}

.summary-card {
    flex: 1;
    background-color: #ffffff;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none; /* Removes underline */
    color: inherit;
}

.summary-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.card-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.card-content h3 {
    font-size: 1.3rem;
    color: #2d3748;
    margin-bottom: 5px;
}

.card-content p {
    font-size: 1rem;
    color: #4a5568;
    margin-bottom: 10px;
}

.card-link span {
    font-size: 0.9rem;
    color: #4299e1;
    cursor: pointer;
}

.actions-section {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.action-button {
    flex: 1;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    color: white;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-decoration: none; /* Removes underline */
}

.action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.income {
    background-color: #48bb78;
}

.transfer {
    background-color: #4299e1;
}

.bill {
    background-color: #e53e3e;
}

.division {
    background-color: #718096;
}
</style>