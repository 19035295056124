<template>
  <div class="selector">
    <label class="section-title">{{ title }}</label>
    <div class="selectors-row">
      <!-- Selection Dropdown -->
      <div class="dropdown">
        <select id="option-select" v-model="localValue" @change="emitChange">
          <option value="" disabled>Select an Option</option>
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.display }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OptionSelector",
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      default: null, // Sync with parent v-model
    },
    initialSelection: {
      type: String,
      default: null, // Default to null
    },
  },
  data() {
    return {
      localValue: null, // Will be set in mounted
    };
  },
  watch: {
    modelValue(newValue) {
      // Watch for changes in the modelValue prop and update localValue accordingly
      this.localValue = newValue || this.getDefaultOption();
    },
  },
  methods: {
    emitChange() {
      // Emit an event when the value changes
      this.$emit("update:modelValue", this.localValue);
      this.$emit("optionSelected", this.localValue);
    },
    getDefaultOption() {
      // Select the first option if initialSelection or modelValue is null
      return this.options.length > 0 ? this.options[0].id : null;
    },
  },
  mounted() {
    // Ensure localValue is set correctly on mount
    this.localValue = this.modelValue || this.initialSelection || this.getDefaultOption();
    this.emitChange(); // Emit initial value
  },
};
</script>

<style scoped>
.selector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #4a5568;
  margin-bottom: 4px;
}

.selectors-row {
  display: flex;
  gap: 12px;
  width: 100%;
}

.dropdown {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dropdown label {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
}

.dropdown select {
  padding: 8px;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}
</style>