<template>
    <BaseLayout :user="user">
        <div class="add-division-page">
            <header class="page-header">
                <h1>{{ isEditing ? 'Edit Division' : 'Add Division' }}</h1>
            </header>

            <form class="division-form" @submit.prevent="submitDivision">
                <div class="row">
                    <FieldErrorWrapper :error="errors.name">
                        <TextField v-model="form.name" title="Division Name" :required="false"></TextField>
                    </FieldErrorWrapper>
                    <FieldErrorWrapper :error="errors.currency">
                        <OptionSelector
                            v-model="form.currency"
                            title="Currency"
                            :options="currencyOptions"
                            style="position: relative; top: -24px"
                        />
                    </FieldErrorWrapper>
                </div>

                <FieldErrorWrapper :error="errors.notes">
                    <TextArea v-model="form.notes" title="Notes"></TextArea>
                </FieldErrorWrapper>

                <FieldErrorWrapper :error="errors.wallet">
                    <OptionSelector v-if="wallets.length > 0"
                        v-model="form.wallet"
                        title="Wallet"
                        :options="walletOptions"
                        style="position: relative; top: -24px"
                    />
                </FieldErrorWrapper>

                <!-- Submit Button -->
                <div class="form-actions">
                    <button class="submit-button" type="submit" :disabled="isLoading">
                        <i class="bi bi-plus-circle"></i>
                        {{ isLoading ? "Submitting..." : isEditing ? "Save Division" : "Add Division" }}
                    </button>
                </div>
            </form>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';
import TextField from '@/components/TextField/TextField.vue';
import TextArea from '@/components/TextArea.vue';
import OptionSelector from '@/components/OptionSelector.vue';
import FieldErrorWrapper from '@/components/FieldErrorWrapper.vue';

export default {
    name: 'EditDivisionPage',
    components: {
        BaseLayout,
        TextField,
        TextArea,
        OptionSelector,
        FieldErrorWrapper,
    },
    data() {
        return {
            form: {
                name: '',
                currency: null,
                notes: '',
                wallet: null,
            },
            isLoading: false,
            errors: {},

            currencyOptions: [
                { id: 'MXN', display: 'MXN' },
                { id: 'USD', display: 'USD' },
                { id: 'EUR', display: 'EUR' },
                { id: 'GBP', display: 'GBP' },
            ],
            wallets: [],

            divisionId: null, // To track if we're editing a division
        };
    },
    computed: {
        isEditing() {
            return this.divisionId !== null; // If divisionId is set, we're editing
        },
        walletOptions() {
            return this.wallets.map((wallet) => ({
                id: wallet.id,
                display: wallet.name,
            }));
        },
    },
    async mounted() {
        this.divisionId = this.$route.params.id || null; // Get divisionId from route params
        await this.loadWallets();
        if (this.isEditing) {
            await this.loadDivisionData();
        }
    },
    methods: {
        async loadDivisionData() {
            try {
                this.isLoading = true;
                const response = await api.get(`wallet/divisions/${this.divisionId}/`);
                const division = response.data;
                this.form = {
                    name: division.name || '',
                    currency: division.balance.currency || null,
                    notes: division.notes || '',
                    wallet: division.wallet || null,
                };
            } catch (error) {
                console.error('Error loading division data:', error);
                alert('Failed to load division data. Please try again.');
                this.$router.push({ name: 'WalletDashboard' }); // Redirect if division cannot be loaded
            } finally {
                this.isLoading = false;
            }
        },
        async loadWallets() {
            try {
                this.isLoading = true;
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results;
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        async submitDivision() {
            try {
                this.isLoading = true;

                // Prepare the data
                const data = { ...this.form };

                var response;

                // Send the data to the backend
                if (this.isEditing) {
                    response = await api.put(`wallet/divisions/${this.divisionId}/`, data);
                } else {
                    response = await api.post('wallet/divisions/', data);
                }

                // Handle the response
                this.$router.push({ name: 'Division', params: { id: response.data.id } });
            } catch (error) {
                const errorResponse = error.response?.data || {};
                this.errors = errorResponse.errors || {};
                //alert(errorResponse.message || 'An error occurred. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>

<style scoped>
.add-division-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 1.8rem;
    color: #2b6cb0;
}

.division-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
}

.row {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.row > * {
    flex: 1; /* Distributes the available space evenly */
}

.form-actions {
    text-align: center;
}

.submit-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.submit-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.submit-button:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
}
</style>
