

export function basePage(path, name, component, meta = {}) {
    const defaultMeta = {
        globalPage: true,
    };

    return {
        path,
        name,
        component,
        meta: { ...defaultMeta, ...meta },
    };
}

export function authenticatedPage(path, name, component, meta = {}) {
    const defaultMeta = {
        requiresAuth: true
    };

    return {
        path,
        name,
        component,
        meta: { ...defaultMeta, ...meta },
    };
}

export function guestPage(path, name, component, meta = {}) {
    const defaultMeta = {
        requiresGuest: true,
        globalPage: true,
    };

    return {
        path,
        name,
        component,
        meta: { ...defaultMeta, ...meta },
    };
}