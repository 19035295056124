<template>
    <div class="date-picker">
      <label :for="id" class="date-picker-label">{{ title }}</label>
      <div class="date-picker-input-wrapper">
        <input
          :id="id"
          type="date"
          class="date-picker-input"
          v-model="localValue"
          @change="emitChange"
        />
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DatePicker",
    props: {
      id: {
        type: String,
        default: () => `date-picker-${Math.random().toString(36).substring(2, 9)}`,
      },
      title: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String, // ISO 8601 Date String (e.g., "2024-11-16")
        default: null,
      },
    },
    data() {
      return {
        localValue: this.modelValue || "", // Sync with parent v-model
      };
    },
    watch: {
      modelValue(newValue) {
        this.localValue = newValue || "";
      },
    },
    methods: {
      emitChange() {
        this.$emit("update:modelValue", this.localValue);
        this.$emit("dateSelected", this.localValue); // Emit custom event
      },
    },
  };
  </script>
  
  <style scoped>
  .date-picker {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
  
  .date-picker-label {
    font-size: 16px;
    font-weight: bold;
    color: #4a5568;
    margin-bottom: 4px;
  }
  
  .date-picker-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .date-picker-input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    color: #2d3748;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .date-picker-input:focus {
    border-color: #4299e1;
    box-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
    outline: none;
  }
  
  .date-picker-input:hover {
    border-color: #a0aec0;
  }
  
  .date-picker-input::placeholder {
    color: #a0aec0;
  }
  </style>
  