<template>
    <BaseLayout>
      <div class="profile-page" v-if="user">
        <!-- Profile Header -->
        <div class="profile-header">
          <h2>{{ user.username }}</h2>
          <p>{{ user.email }}</p>
        </div>
  
        <!-- Profile Details -->
        <div class="profile-details">
          <h3>Profile Information</h3>
          <ul>
            <li><strong>Full Name:</strong> {{ user.full_name || 'Not provided' }}</li>
            <li><strong>Email:</strong> {{ user.email }}</li>
            <li><strong>Phone:</strong> {{ user.phone || 'Not provided' }}</li>
          </ul>
        </div>
  
        <!-- Logout Button -->
        <div class="logout-section">
          <button @click="logout" class="logout-button">Logout</button>
        </div>
      </div>
    </BaseLayout>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import BaseLayout from '@/components/layouts/BaseLayout.vue';
  
  export default {
    name: 'ProfilePage',
    components: {
        BaseLayout,
      },
    computed: {
      ...mapState(['user']),
    },
    methods: {
      logout() {
        this.$store.dispatch('logout').then(() => {
          this.$redirectToPage('LoginPage');
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .profile-page {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .profile-header {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .profile-avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .profile-header h2 {
    margin: 0;
    font-size: 24px;
  }
  
  .profile-header p {
    color: #666;
  }
  
  .profile-details {
    text-align: left;
    margin-top: 20px;
  }
  
  .profile-details h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .profile-details ul {
    list-style: none;
    padding: 0;
  }
  
  .profile-details li {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .logout-section {
    margin-top: 30px;
  }
  
  .logout-button {
    background-color: #ff4757;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #e84118;
  }
  </style>
  