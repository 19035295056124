<template>
    <div class="custom-text-field">
      <label :class="{ 'float-up': modelValue || isFocused }" class="custom-title">{{ title }}</label>
      <textarea
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="isFocused = true"
        @blur="isFocused = false"
        :required="required"
      ></textarea>
    </div>
  </template>
  
  <script>
  export default {
    name: "CustomTextField",
    props: {
      modelValue: {
        type: String,
        default: "",
      },
      title: {
        type: String,
        default: "",
      },
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFocused: false,
      };
    },
  };
  </script>
  
  <style scoped>
  .custom-text-field {
    position: relative;
    margin-bottom: 20px;
  }
  
  .custom-title {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
    color: #aaa;
    transition: all 0.3s ease;
    pointer-events: none;
  }
  
  .float-up {
    top: -14px;
    font-size: 16px;
    color: #007bff;
    font-weight: bold;
    color: #4a5568;
  }
  
  .custom-text-field textarea {
    padding: 16px 12px 10px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .custom-text-field textarea:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
  }
  
  .custom-text-field textarea:hover {
    border-color: #007bff;
  }
  </style>
  