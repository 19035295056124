<template>
    <BaseLayout :user="user">
        <div class="division-details-page">
            <header class="page-header">
                <h1>Division Details</h1>
            </header>

            <section v-if="isLoading" class="loading-section">
                Loading division details...
            </section>

            <section v-else class="division-details-section">
                <div class="detail-card">
                    <h2>{{ division.name || "Unnamed Division" }}</h2>
                    <p>
                        <span class="balance-icon" :class="getBalanceClass(division.balance.amount)">
                            <i v-if="division.balance.amount > 0" class="bi bi-arrow-up-circle"></i>
                            <i v-if="division.balance.amount < 0" class="bi bi-arrow-down-circle"></i>
                        </span>
                        <strong>Balance:</strong>
                        ${{ formatAmount(division.balance.amount) }} 
                        {{ division.balance.currency }}
                    </p>
                    <p>
                        <strong>Wallet:</strong> 
                        {{ walletName }}
                    </p>
                    <p>
                        <strong>Description:</strong> 
                        {{ division.description || "No description provided" }}
                    </p>
                </div>

                <div class="action-buttons">
                    <button class="back-button" @click="goBack">Back to Divisions</button>
                    <button class="edit-button" @click="editDivision">Edit Division</button>
                    <button class="delete-button" @click="deleteDivision">Delete Division</button>
                </div>
            </section>

            <!-- Toggler for Transactions and Scheduled Transactions -->
            <section class="toggler-section">
                <button
                    :class="{ active: isViewingTransactions }"
                    @click="showTransactions"
                >
                    Transactions
                </button>
                <button
                    :class="{ active: !isViewingTransactions }"
                    @click="showScheduledTransactions"
                >
                    Scheduled Transactions
                </button>
            </section>

            <!-- Transactions Section -->
            <section v-if="isViewingTransactions">
                <div class="transactions-section-header">
                    <h2>Transactions</h2>
                    <button class="add-transaction-button" @click="goToAddTransaction">
                        <i class="bi bi-plus-circle"></i> Add Transaction
                    </button>
                </div>
                <div v-if="transactions.length > 0" class="transactions-section">
                    <div class="transactions-list">
                        <TransactionMiniview v-for="transaction in transactions" :key="transaction.id" :transaction="transaction" :divisionId="division.id" />
                    </div>
                </div>
                <div v-else class="no-transactions-section">
                    <p>No transactions found for this division.</p>
                </div>
            </section>

            <!-- Scheduled Transactions Section -->
            <section v-if="!isViewingTransactions">
                <div class="transactions-section-header">
                    <h2>Scheduled Transactions</h2>
                    <button class="add-transaction-button" @click="goToAddScheduledTransaction">
                        <i class="bi bi-plus-circle"></i> Add Scheduled Transaction
                    </button>
                </div>
                <div v-if="scheduledTransactions.length > 0" class="transactions-section">
                    <div class="transactions-list">
                        <ScheduledTransactionMiniview v-for="scheduledTransaction in scheduledTransactions" :key="scheduledTransaction.id" :scheduledTransaction="scheduledTransaction" :divisionId="division.id" />
                    </div>
                </div>
                <div v-else class="no-transactions-section">
                    <p>No scheduled transactions found for this division.</p>
                </div>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import TransactionMiniview from '@/Apps/Wallet/Components/Transactions/TransactionMiniview.vue';
import ScheduledTransactionMiniview from '@/Apps/Wallet/Components/Transactions/Scheduled/ScheduledTransactionMiniview.vue';
import api from '@/services/api';

export default {
    name: 'DivisionDetailsView',
    components: {
        BaseLayout,
        TransactionMiniview,
        ScheduledTransactionMiniview,
    },
    data() {
        return {
            division: null,
            walletName: null,
            transactions: [],
            scheduledTransactions: [],
            isLoading: true,
            isViewingTransactions: true, // Toggles between transactions and scheduled transactions
        };
    },
    async mounted() {
        await this.loadDivision();
        await this.loadTransactions();
        await this.loadScheduledTransactions();
    },
    methods: {
        async loadDivision() {
            try {
                const divisionId = this.$route.params.id;
                const response = await api.get(`wallet/divisions/${divisionId}/`);
                this.division = response.data;

                // Fetch the wallet name
                if (this.division.wallet) {
                    this.walletName = await this.getWalletName(this.division.wallet);
                }
            } catch (error) {
                console.error('Error loading division details:', error);
                alert('Failed to load division details. Please try again.');
                this.$router.push({ name: 'Divisions' });
            } finally {
                this.isLoading = false;
            }
        },
        async loadTransactions() {
            try {
                const divisionId = this.$route.params.id;
                const response = await api.get(`wallet/transactions/?division=${divisionId}`);
                this.transactions = response.data.results || [];
            } catch (error) {
                console.error('Error loading transactions:', error);
                alert('Failed to load transactions. Please try again.');
            }
        },
        async loadScheduledTransactions() {
            try {
                const divisionId = this.$route.params.id;
                const response = await api.get(`wallet/scheduled-transactions/?division=${divisionId}`);
                this.scheduledTransactions = response.data.results || [];
            } catch (error) {
                console.error('Error loading scheduled transactions:', error);
                alert('Failed to load scheduled transactions. Please try again.');
            }
        },
        async getWalletName(walletId) {
            try {
                const response = await api.get(`wallet/wallets/${walletId}/`);
                return response.data.name;
            } catch (error) {
                console.error('Error loading wallet name:', error);
                return 'Unknown Wallet';
            }
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(Math.abs(amount) / 100); // Add thousands separator
        },
        getBalanceClass(amount) {
            if (amount > 0) return 'positive';
            if (amount < 0) return 'negative';
            return 'neutral';
        },
        goToAddTransaction() {
            this.$router.push({
                name: 'NewTransaction',
                query: { division: this.division.id }, // Include division ID as query parameter
            });
        },
        goToAddScheduledTransaction() {
            this.$router.push({
                name: 'NewScheduledTransaction',
                query: { division: this.division.id }, // Include division ID as query parameter
            });
        },
        showTransactions() {
            this.isViewingTransactions = true;
        },
        showScheduledTransactions() {
            this.isViewingTransactions = false;
        },
    },
};
</script>

<style scoped>
.division-details-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.detail-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detail-card h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.detail-card p {
    font-size: 1rem;
    color: #4a5568;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78; /* Green for positive balances */
}

.negative {
    color: #e53e3e; /* Red for negative balances */
}

.neutral {
    display: none; /* No icon for zero balances */
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 20px;
}

.back-button {
    background-color: #4299e1;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.edit-button {
    background-color: #48bb78;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.edit-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.delete-button {
    background-color: #e53e3e;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.delete-button:hover {
    background-color: #c53030;
    transform: translateY(-2px);
}

.toggler-section {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
}

.toggler-section button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: #e2e8f0;
    color: #2d3748;
}

.toggler-section button.active {
    background-color: #4299e1;
    color: white;
}

.toggler-section button:hover {
    background-color: #cbd5e0;
}

.transactions-section {
    margin-top: 20px;
}

.transactions-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.transactions-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.transactions-section-header h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
}

.add-transaction-button {
    background-color: #48bb78;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 8px 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.add-transaction-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}
</style>