<template>
    <div class="option-selector">
        <label for="option-dropdown" style="text-align: center;">{{ title }}</label>
        <select
            id="option-dropdown"
            v-model="selectedOption"
            @change="emitOptionChange"
            :disabled="isLoading"
        >
            <option value="" disabled>{{ selectOptionText }}</option>
            <option v-for="option in optionsDisplay" :key="option.id" :value="option.id">
                {{ option.display }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    name: "DropdownMenu",
    props: {
        options: {
            type: Array,
            required: true,
        },
        initialOption: {
            type: Object,
            default: null, // Pre-select an option if provided
        },
        isLoading: {
            type: Boolean,
            default: false, // Disable the dropdown if loading
        },
        title: {
            type: String,
            default: "Select an option",
        },
        selectOptionText: {
            type: String,
            default: "Select an option",
        },
        displayKey: {
            type: String,
            default: "name", // The key to use for display
        },
    },
    data() {
        return {
            selectedOption: null, // Track the selected option
        };
    },
    computed: {
        optionsDisplay() {
            return this.options.map((option) => ({
                id: option.id,
                display: option[this.displayKey],
            }));
        },
    },
    watch: {
        options: {
            immediate: true,
            handler(newOptions) {
                // When options change, set the selected option
                if (this.initialOption) {
                    this.selectedOption = this.initialOption.id;
                } else if (newOptions.length > 0) {
                    this.selectedOption = newOptions[0].id; // Default to the first option
                    this.emitOptionChange(); // Ensure the parent is notified
                }
            },
        },
    },
    methods: {
        emitOptionChange() {
            const selectedOption = this.options.find(
                (option) => option.id === this.selectedOption
            );
            this.$emit("optionChanged", selectedOption || null); // Emit the selected option object
        },
    },
};
</script>

<style scoped>
.option-selector {
    margin-top: 10px;
    text-align: left;
}

.option-selector label {
    display: block;
    font-size: 0.875rem;
    font-weight: bold;
    color: #4a5568;
    margin-bottom: 4px;
}

.option-selector select {
    width: 100%;
    padding: 10px;
    border: 1px solid #cbd5e0;
    border-radius: 8px;
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
}

.option-selector select:focus {
    border-color: #4299e1;
    box-shadow: 0 0 5px rgba(66, 153, 225, 0.5);
}

.option-selector select:disabled {
    background-color: #f7fafc;
    cursor: not-allowed;
}
</style>