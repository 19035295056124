<template>
    <TextField
        :title="title"
        :modelValue="formattedValue"
        @update:modelValue="handleCurrencyInput"
    />
</template>

<script>
import TextField from "@/components/TextField/TextField.vue";

export default {
    name: "CurrencyTextField",
    components: {
        TextField,
    },
    props: {
        modelValue: {
            type: [String, Number],
            default: "",
        },
        title: {
            type: String,
            default: "",
        },
    },
    computed: {
        formattedValue() {
            const num = parseFloat(this.modelValue) || 0;
            return `$${(num / 100).toFixed(2)}`;
        },
    },
    methods: {
        handleCurrencyInput(value) {
            // Remove non-numeric characters
            const numericValue = value.replace(/[^\d]/g, ""); 

            // Emit only numeric characters
            this.$emit("update:modelValue", numericValue);
        },
    },
};
</script>