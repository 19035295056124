import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';
import { basePage, authenticatedPage, guestPage } from '@/router/helpers';

import AdminRoutes from '@/Apps/Admin/router';

import LifeRoutes from '@/Apps/Life/router';
import WalletRoutes from '@/Apps/Wallet/router';

import LoginPage from '@/views/LoginPage.vue'
import HomePage from '@/views/HomePage/HomePage.vue'
import ProfilePage from '@/views/ProfilePage.vue';

const mainRoutes = [
  basePage('/', 'HomePage', HomePage),
  guestPage('/login/', 'LoginPage', LoginPage),
  authenticatedPage('/profile/', 'ProfilePage', ProfilePage),
]

const routes = [
  ...mainRoutes,
  ...AdminRoutes,
  ...LifeRoutes,
  ...WalletRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);

  const globalPage = to.matched.some((record) => record.meta.globalPage);
  
  // Check if we already have the token in Vuex or localStorage
  const token = store.state.token || localStorage.getItem('authToken');

  if (token && !store.state.user) {
    // If the user is not loaded in the store but a token exists, fetch the user data
    await store.dispatch('checkAuthStatus');
  }

  const isAuthenticated = store.getters.isAuthenticated;

  if (!isAuthenticated) {
    store.commit('setSelectedApp', null);
    store.commit('setUserApps', []);
  }

  if (globalPage) {
    store.commit('setSelectedApp', null);
  }

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated, redirect to login
    next({ name: 'Login' });
  } else if ((requiresGuest && isAuthenticated)) {
    // If the user is authenticated but tries to access a guest route, redirect to home
    next({ name: 'Home' });
  } else {
    // Otherwise, allow the navigation
    next();
  }
});

export default router
