<template>
    <BaseLayout :user="user">
        <div class="wallet-page">
            <header class="page-header">
                <h1>Wallet: {{ wallet?.name || "Loading..." }}</h1>
            </header>

            <section v-if="isLoading" class="loading-section">
                Loading wallet details...
            </section>

            <section v-else-if="wallet" class="wallet-details-section">
                <div class="detail-card">
                    <h2>{{ wallet.name }}</h2>
                    <div class="balances">
                        <p>
                            <span class="balance-icon" :class="getBalanceClass(wallet.balance.amount)">
                                <i v-if="wallet.balance.amount > 0" class="bi bi-arrow-up-circle"></i>
                                <i v-if="wallet.balance.amount < 0" class="bi bi-arrow-down-circle"></i>
                            </span>
                            <strong>Balance:</strong>
                            ${{ formatAmount(wallet.balance.amount) }} 
                            {{ wallet.balance.currency }}
                        </p>
                        <p>
                            <span class="balance-icon" :class="getBalanceClass(wallet.assets.amount)">
                                <i v-if="wallet.assets.amount > 0" class="bi bi-arrow-up-circle"></i>
                                <i v-if="wallet.assets.amount < 0" class="bi bi-arrow-down-circle"></i>
                            </span>
                            <strong>Assets:</strong>
                            ${{ formatAmount(wallet.assets.amount) }} 
                            {{ wallet.assets.currency }}
                        </p>
                        <p>
                            <span class="balance-icon" :class="getBalanceClass(wallet.liabilities.amount)">
                                <i v-if="wallet.liabilities.amount > 0" class="bi bi-arrow-up-circle"></i>
                                <i v-if="wallet.liabilities.amount < 0" class="bi bi-arrow-down-circle"></i>
                            </span>
                            <strong>Liabilities:</strong>
                            ${{ formatAmount(wallet.liabilities.amount) }} 
                            {{ wallet.liabilities.currency }}
                        </p>
                    </div>
                </div>

                <div class="action-buttons">
                    <button class="delete-button" @click="deleteWallet">Delete Wallet</button>
                    <button class="share-button" @click="shareWallet">Share Wallet</button>
                    <router-link
                        :to="{ name: 'EditWallet', params: { id: wallet.id } }"
                        class="edit-button">Edit Wallet
                    </router-link>
                </div>

                <!-- Share Section -->
                <section v-if="shareData" class="share-section">
                    <div class="share-header">
                        <button
                            :class="{ active: selectedShareOption === 'link' }"
                            @click="selectedShareOption = 'link'"
                        >
                            Share Link
                        </button>
                        <button
                            :class="{ active: selectedShareOption === 'code' }"
                            @click="selectedShareOption = 'code'"
                        >
                            Share Code
                        </button>
                    </div>
                    <div class="share-content">
                        <div v-if="selectedShareOption === 'link'">
                            <h3>Share Link</h3>
                            <p>
                                Share this link with the user:
                                <a :href="shareData.link" target="_blank">Click to redirect</a>
                            </p>
                        </div>
                        <div v-if="selectedShareOption === 'code'">
                            <h3>Share Code</h3>
                            <p>Share this code with the user: <strong>{{ shareData.code }}</strong></p>
                        </div>
                    </div>
                </section>
            </section>

            <section v-if="divisions.length > 0" class="divisions-section">
                <h2>Divisions</h2>
                <div class="divisions-list">
                    <router-link
                        :to="{ name: 'Division', params: { id: division.id } }"
                        class="division-card"
                        v-for="division in divisions"
                        :key="division.id"
                    >
                        <div class="division-info">
                            <h3>{{ division.name }}</h3>
                            <p>
                                <span class="balance-icon" :class="getBalanceClass(division.balance.amount)">
                                    <i v-if="division.balance.amount > 0" class="bi bi-arrow-up-circle"></i>
                                    <i v-if="division.balance.amount < 0" class="bi bi-arrow-down-circle"></i>
                                </span>
                                <strong>Balance:</strong>
                                ${{ formatAmount(division.balance.amount) }} 
                                {{ division.balance.currency }}
                            </p>
                        </div>
                    </router-link>
                </div>
                <button
                    v-if="hasMoreDivisions"
                    @click="loadMoreDivisions"
                    class="load-more-button"
                >
                    Load More Divisions
                </button>
            </section>

            <section v-else class="no-divisions-section">
                <p>No divisions found. Add a division to this wallet.</p>
            </section>

            <section class="add-division-section">
                <button class="add-division-button" @click="addDivision">
                    <i class="bi bi-plus-circle"></i> Add Division
                </button>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';

export default {
    name: 'WalletPage',
    components: {
        BaseLayout,
    },
    data() {
        return {
            wallet: null,
            divisions: [],
            isLoading: true,
            isLoadingDivisions: false,
            nextDivisionsPage: null,
            hasMoreDivisions: true,
            shareData: null,
            selectedShareOption: 'link',
        };
    },
    async mounted() {
        await this.loadWallet();
        await this.loadDivisions();
    },
    methods: {
        async loadWallet() {
            try {
                this.isLoading = true;
                const walletId = this.$route.params.id;
                const response = await api.get(`wallet/wallets/${walletId}/`);
                this.wallet = response.data;
            } catch (error) {
                console.error('Error loading wallet details:', error);
                alert('Failed to load wallet details. Please try again.');
                this.$router.push({ name: 'Wallets' });
            } finally {
                this.isLoading = false;
            }
        },
        async loadDivisions() {
            try {
                this.isLoadingDivisions = true;
                const walletId = this.$route.params.id;
                const response = await api.get(`wallet/divisions/?wallet=${walletId}`);
                this.divisions = response.data.results || [];
                this.nextDivisionsPage = response.data.next;
                this.hasMoreDivisions = !!response.data.next;
            } catch (error) {
                console.error('Error loading divisions:', error);
                alert('Failed to load divisions. Please try again.');
            } finally {
                this.isLoadingDivisions = false;
            }
        },
        async loadMoreDivisions() {
            if (!this.nextDivisionsPage) return;

            try {
                this.isLoadingDivisions = true;

                let nextPath = new URL(this.nextDivisionsPage).pathname + new URL(this.nextDivisionsPage).search;

                if (nextPath.startsWith('/api/')) {
                    nextPath = nextPath.replace('/api/', '');
                }

                const response = await api.get(nextPath);
                this.divisions = [...this.divisions, ...response.data.results];
                this.nextDivisionsPage = response.data.next;
                this.hasMoreDivisions = !!response.data.next;
            } catch (error) {
                console.error('Error loading more divisions:', error);
                alert('Failed to load more divisions. Please try again.');
            } finally {
                this.isLoadingDivisions = false;
            }
        },
        async shareWallet() {
            if (this.shareData) {
                this.shareData = null;
                return;
            }
            try {
                const response = await api.post(`wallet/wallets/${this.wallet.id}/share/`);
                this.shareData = response.data;

                this.shareData.link = this.$router.resolve({ name: 'ConnectToWallet' }).href + `?share_code=${this.shareData.code}`;
                this.selectedShareOption = 'link';
            } catch (error) {
                console.error('Error sharing wallet:', error);
                alert('Failed to generate share details. Please try again.');
            }
        },
        async deleteWallet() {
            if (!confirm('Are you sure you want to delete this wallet? This action cannot be undone.')) return;
            try {
                this.isLoading = true;
                await api.delete(`wallet/wallets/${this.wallet.id}/`);
                this.$router.push({ name: 'Wallets' });
            } catch (error) {
                console.error('Error deleting wallet:', error);
                alert('Failed to delete wallet. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(Math.abs(amount) / 100);
        },
        getBalanceClass(amount) {
            if (amount > 0) return 'positive';
            if (amount < 0) return 'negative';
            return 'neutral';
        },
        addDivision() {
            this.$router.push({ name: 'NewDivision' });
        },
    },
};
</script>

<style scoped>
.wallet-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.loading-section {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
}

.detail-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.detail-card h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.detail-card p {
    font-size: 1rem;
    color: #4a5568;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 5px;
}

.balances {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78;
}

.negative {
    color: #e53e3e;
}

.neutral {
    display: none;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.edit-button,
.share-button,
.delete-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
}

.edit-button {
    background-color: #48bb78;
    color: white;
}

.edit-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.share-button {
    background-color: #4299e1;
    color: white;
}

.share-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
}

.delete-button {
    background-color: #e53e3e;
    color: white;
}

.delete-button:hover {
    background-color: #c53030;
    transform: translateY(-2px);
}

.divisions-section h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 15px;
}

.divisions-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.division-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
}

.division-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.division-info h3 {
    font-size: 1.2rem;
    color: #2b6cb0;
}

.division-info p {
    font-size: 1rem;
    color: #4a5568;
    display: flex;
    align-items: center;
    gap: 5px;
}

.load-more-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #4299e1;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.load-more-button:hover {
    background-color: #2b6cb0;
}

.add-division-section {
    margin-top: 20px;
    text-align: right;
}

.add-division-button {
    background-color: #48bb78;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.add-division-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.share-section {
    margin-top: 20px;
    background-color: #f7fafc;
    border: 1px solid #e2e8f0;
    border-radius: 12px;
    padding: 20px;
}

.share-header {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.share-header button {
    background-color: #edf2f7;
    border: 1px solid #e2e8f0;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
}

.share-header button.active {
    background-color: #4299e1;
    color: white;
}

.share-content {
    text-align: left;
}
</style>