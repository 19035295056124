<template>
    <router-link :to="{ name: 'ScheduledTransaction', params: { id: scheduledTransaction.id } }" v-if="useLink">
        <div class="transaction-card">
            <div class="transaction-info">
                <h3>{{ scheduledTransaction.name || "Unnamed Scheduled Transaction" }}</h3>
                <p>
                    <span class="balance-icon">
                        <i :class="$balanceIcon(scheduledTransaction.balance, scheduledTransaction.origin !== null && divisionId === null, scheduledTransaction.origin === divisionId)"></i>
                    </span>
                    <strong>Amount:</strong>
                    {{ $balanceString(scheduledTransaction.balance) }}
                </p>
                <p v-if="scheduledTransaction.date">
                    <strong>Date:</strong> 
                    {{ $dateString(scheduledTransaction.date) }}
                </p>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'ScheduledTransactionMiniview',
    props: {
        scheduledTransaction: {
            type: Object,
            required: true,
        },
        divisionId: {
            type: String,
            required: false,
            default: null,
        },
        useLink: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
};
</script>

<style scoped>
.transaction-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    color: inherit;
}

.transaction-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.transaction-info h3 {
    font-size: 1.2rem;
    color: #2b6cb0;
}

.transaction-info p {
    font-size: 1rem;
    color: #4a5568;
    display: flex;
    align-items: center;
    gap: 5px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78;
}

.negative {
    color: #e53e3e;
}
</style>