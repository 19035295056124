import WalletDashboardPage from "./Views/WalletDashboardPage.vue";

import WalletsPage from "./Views/Wallets/WalletsPage.vue";
import DivisionsPage from "./Views/Divisions/DivisionsPage.vue";
import TransactionsPage from "./Views/Transactions/TransactionsPage.vue";
import ScheduledTransactionsPage from "./Views/Transactions/Scheduled/ScheduledTransactionsPage.vue";
import ProposedTransactionsPage from "./Views/Transactions/Proposed/ProposedTransactionsPage.vue";

import WalletPage from "./Views/Wallets/WalletPage.vue";
import DivisionPage from "./Views/Divisions/DivisionPage.vue";
import TransactionPage from "./Views/Transactions/TransactionPage.vue";
import ScheduledTransactionPage from "./Views/Transactions/Scheduled/ScheduledTransactionPage.vue";
import ProposedTransactionPage from "./Views/Transactions/Proposed/ProposedTransactionPage.vue";

import EditWalletPage from "./Views/Wallets/EditWalletPage.vue";
import EditDivisionPage from "./Views/Divisions/EditDivisionPage.vue";
import EditTransactionPage from "./Views/Transactions/EditTransactionPage.vue";
import EditScheduledTransactionPage from "./Views/Transactions/Scheduled/EditScheduledTransactionPage.vue";
import EditProposedTransactionPage from "./Views/Transactions/Proposed/EditProposedTransactionPage.vue";

import ConnectToWalletPage from "./Views/Wallets/ConnectToWalletPage.vue";
//import HomePage from '@/views/HomePage/HomePage.vue'
import { authenticatedPage } from '@/router/helpers';

const WalletRoutes = [
    authenticatedPage('/wallet/', 'WalletDashboard', WalletDashboardPage),

    authenticatedPage('/wallet/wallets/', 'Wallets', WalletsPage),
    authenticatedPage('/wallet/wallets/connect/', 'ConnectToWallet', ConnectToWalletPage),
    authenticatedPage('/wallet/wallets/:id/', 'Wallet', WalletPage),
    authenticatedPage('/wallet/divisions/', 'Divisions', DivisionsPage),
    authenticatedPage('/wallet/divisions/:id/', 'Division', DivisionPage),
    authenticatedPage('/wallet/transactions/', 'Transactions', TransactionsPage),
    authenticatedPage('/wallet/transactions/:id/', 'Transaction', TransactionPage),
    authenticatedPage('/wallet/transactions/scheduled/', 'ScheduledTransactions', ScheduledTransactionsPage),
    authenticatedPage('/wallet/transactions/scheduled/:id/', 'ScheduledTransaction', ScheduledTransactionPage),
    authenticatedPage('/wallet/transactions/proposed/', 'ProposedTransactions', ProposedTransactionsPage),
    authenticatedPage('/wallet/transactions/proposed/:id/', 'ProposedTransaction', ProposedTransactionPage),

    authenticatedPage('/wallet/wallets/new/', 'NewWallet', EditWalletPage),
    authenticatedPage('/wallet/wallets/edit/:id/', 'EditWallet', EditWalletPage),
    authenticatedPage('/wallet/divisions/new/', 'NewDivision', EditDivisionPage),
    authenticatedPage('/wallet/divisions/edit/:id/', 'EditDivision', EditDivisionPage),
    authenticatedPage('/wallet/transactions/new/', 'NewTransaction', EditTransactionPage),
    authenticatedPage('/wallet/transactions/edit/:id/', 'EditTransaction', EditTransactionPage),
    authenticatedPage('/wallet/transactions/scheduled/new/', 'NewScheduledTransaction', EditScheduledTransactionPage),
    authenticatedPage('/wallet/transactions/scheduled/edit/:id/', 'EditScheduledTransaction', EditScheduledTransactionPage),
    authenticatedPage('/wallet/transactions/proposed/new/', 'NewProposedTransaction', EditProposedTransactionPage),
    authenticatedPage('/wallet/transactions/proposed/edit/:id/', 'EditProposedTransaction', EditProposedTransactionPage),
    /*{
        path: '/wallet/',  // The base path for the sub-app
        children: [
            {
                path: '',
                name: 'WalletDashboard',
                component: WalletDashboardPage,
                meta: { requiresAuth: true }
            },
        ],
    }*/
];

export default WalletRoutes;
