export default {
  install(app) {
    app.config.globalProperties.$balanceString = (balance) => {
      const formattedAmount = new Intl.NumberFormat('en-US').format(Math.abs(balance.amount) / 100);
      return `$${formattedAmount} ${balance.currency}`;
    };

    app.config.globalProperties.$dateString = (date) => {
      return new Date(date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
      });
    };

    app.config.globalProperties.$balanceIcon = (balance, asTransfer, isOrigin) => {
      if (asTransfer) {
        return 'bi bi-arrow-left-right transfer';
      }
      if (balance.amount < 0) {
        return isOrigin ? 'bi bi-arrow-up-circle positive' : 'bi bi-arrow-down-circle negative';
      }
      if (balance.amount > 0) {
        return isOrigin ? 'bi bi-arrow-down-circle negative' : 'bi bi-arrow-up-circle positive';
      }
      return 'bi bi-dash-circle no-transaction';
    };
  },
};