<template>
    <BaseLayout :user="user">
        <div class="wallets-page">
            <header class="page-header">
                <h1>Wallets</h1>
            </header>

            <section v-if="isLoading" class="loading-section">
                Loading wallets...
            </section>

            <section v-else>
                <div v-if="wallets.length === 0" class="no-wallets">
                    No wallets found. Click the button below to add a new wallet.
                </div>

                <div class="wallet-actions">
                    <router-link
                        :to="{ name: 'ConnectToWallet' }"
                        class="connect-wallet-button">
                        <i class="bi bi-link-45deg"></i> Connect Shared Wallet
                    </router-link>
                    <button class="add-wallet-button" @click="addWallet">
                        <i class="bi bi-plus-circle"></i> Add Wallet
                    </button>
                </div>

                <div class="wallets-list">
                    <router-link 
                        :to="{ name: 'Wallet', params: { id: wallet.id } }"
                        class="wallet-card"
                        v-for="wallet in wallets"
                        :key="wallet.id"
                    >
                        <div class="wallet-info">
                            <h2>{{ wallet.name }}</h2>
                            <p>
                                <span class="balance-icon" :class="getBalanceClass(wallet.balance.amount)">
                                    <i v-if="wallet.balance.amount > 0" class="bi bi-arrow-up-circle"></i>
                                    <i v-if="wallet.balance.amount < 0" class="bi bi-arrow-down-circle"></i>
                                </span>
                                <strong>Balance:</strong>
                                ${{ formatAmount(wallet.balance.amount) }} 
                                {{ wallet.balance.currency }}
                            </p>
                        </div>
                    </router-link>
                </div>
            </section>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/layouts/BaseLayout.vue';
import api from '@/services/api';

export default {
    name: 'WalletsView',
    components: {
        BaseLayout,
    },
    data() {
        return {
            wallets: [],
            isLoading: true,
        };
    },
    async mounted() {
        await this.loadWallets();
    },
    methods: {
        async loadWallets() {
            try {
                this.isLoading = true;
                const response = await api.get('wallet/wallets/');
                this.wallets = response.data.results || [];
            } catch (error) {
                console.error('Error loading wallets:', error);
                alert('Failed to load wallets. Please try again.');
            } finally {
                this.isLoading = false;
            }
        },
        formatAmount(amount) {
            return new Intl.NumberFormat('en-US').format(Math.abs(amount) / 100); // Add thousands separator
        },
        getBalanceClass(amount) {
            if (amount > 0) return 'positive';
            if (amount < 0) return 'negative';
            return 'neutral';
        },
        addWallet() {
            this.$router.push({ name: 'NewWallet' });
        },
        connectSharedWallet() {
            this.$router.push({ name: 'ConnectWallet' }); // Navigate to the Connect Wallet Page
        },
    },
};
</script>

<style scoped>
.wallets-page {
    font-family: 'Inter', sans-serif;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f7fafc;
    color: #2d3748;
}

.page-header {
    text-align: center;
    margin-bottom: 20px;
}

.page-header h1 {
    font-size: 2rem;
    color: #2b6cb0;
}

.loading-section {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
}

.no-wallets {
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
    margin-bottom: 20px;
}

.wallet-actions {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-wallet-button,
.connect-wallet-button {
    background-color: #48bb78;
    color: white;
    font-size: 0.9rem;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
}

.add-wallet-button:hover,
.connect-wallet-button:hover {
    background-color: #38a169;
    transform: translateY(-2px);
}

.connect-wallet-button {
    background-color: #4299e1;
}

.connect-wallet-button:hover {
    background-color: #2b6cb0;
}

.wallets-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.wallet-card {
    background-color: white;
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-decoration: none; /* Removes underline */
    color: inherit; /* Inherit text color */
}

.wallet-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.wallet-info h2 {
    font-size: 1.5rem;
    color: #2b6cb0;
    margin-bottom: 10px;
}

.wallet-info p {
    font-size: 1rem;
    color: #4a5568;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.balance-icon i {
    font-size: 1.2rem;
    vertical-align: middle;
}

.positive {
    color: #48bb78; /* Green for positive balances */
}

.negative {
    color: #e53e3e; /* Red for negative balances */
}

.neutral {
    display: none; /* No icon for zero balances */
}
</style>