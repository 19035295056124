import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import globalFunctions from './plugins/globalFunctions';

import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/global.css';

const app = createApp(App);

app.config.globalProperties.$redirectToPage = function(routeName, params = {}) {
    // Check if params object is empty (no parameters provided)
    if (Object.keys(params).length === 0) {
      this.$router.push({ name: routeName }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    } else {
      // If parameters are provided, include them in the navigation
      this.$router.push({ name: routeName, params }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    }
  };

app.use(store);
app.use(router);
app.use(globalFunctions);
app.mount('#app');